export enum PaymentType {
	CreditCard = 1,
	AmericanExpress = 2,
	Visa = 3,
	Mastercard = 4,
	Amex = 5,
	Discover = 6,
	Check = 7,
	Wire = 8,
	CustomerCredit = 9,
	CreditKey = 10,
	CreditKeyPostAuth = 11,
	Cash = 12,
	PurchaseOrder = 13,
	MoneyOrder = 14,
	StaplesPOS = 15,
	Chargeback = 16,
	ACH = 17
}
