import { Component, Input, ViewChild, OnInit, OnChanges } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions,
	ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
import * as _ from 'lodash';

export type SparkLineOptions = {
	series: ApexAxisChartSeries;
	chart?: ApexChart;
	xaxis?: ApexXAxis;
	markers?: any; //ApexMarkers;
	stroke?: any; //ApexStroke;
	yaxis?: ApexYAxis | ApexYAxis[];
	plotOptions?: ApexPlotOptions;
	dataLabels?: ApexDataLabels;
	colors?: string[];
	labels?: string[] | number[];
	title?: ApexTitleSubtitle;
	subtitle?: ApexTitleSubtitle;
	legend?: ApexLegend;
	fill?: ApexFill;
	tooltip?: ApexTooltip;
};

declare global {
	interface Window {
		Apex: any;
	}
}

@Component({
	selector: 'app-chart-spark-line',
	templateUrl: './chart-spark-line.component.html',
	styleUrls: ['./chart-spark-line.component.scss']
})

export class ChartSparkLineComponent implements OnInit, OnChanges {
	@ViewChild('sparklineChart') chart!: ChartComponent;

	@Input() chartOptions: Partial<SparkLineOptions> = {};
	@Input() lineColor: any = [255,255,255,1];

	commonLineSparklineOptions: Partial<SparkLineOptions> = {
		chart: {
		  type: "area",
		  height: 35,
		  sparkline: {
			enabled: true
		  },
		},
		xaxis: {
			type: 'datetime'
		},
		stroke: {
			curve: 'smooth',
			width: 3,
		},
		tooltip: {
			fixed: {
				enabled: false
			},
			x: {
				show: true,
				format: 'dd MMM yyyy'
			},
			y: {
				title: {
					formatter: function (seriesName) {
						return "";
					}
				},
				formatter: function (value, { series, seriesIdx, dataPtIdx, w }) {
					return value.toFixed(0);
				}
			},
			marker: {
				show: false
			}
		},
		colors: ["#FFFFFF"]
	  };

	  appliedSparkLineOptions: Partial<SparkLineOptions> = {};

	  ngOnInit() {
		  //this.appliedSparkLineOptions = Object.assign({}, this.commonLineSparklineOptions, this.chartOptions);
		  this.appliedSparkLineOptions = _.merge({}, this.commonLineSparklineOptions, this.chartOptions);
	  }
	  ngOnChanges() {
		
		this.chart.updateSeries([
			{
				name: this.chartOptions.series![0].name,
				data: this.chartOptions.series![0].data
			}
		]);
	  }
}
