import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter, take } from 'rxjs/operators';
import { DistributionsService } from 'services/distributions.service';
import { GetCustomerDistributionsQuery, MigrateDistributionQuery, CustomerSearchMapTransfer, CustomerDistributionWithRelatedOrders, Customer, Site,
	USelectType, PaginationQuery, CaCombineMapsQuery, CombineListQuery, ListsToCombine } from '@taradel/admin-api-client';
import { NgbModal, NgbModalRef, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { MapDataService } from 'services/map-data.service';
import { CustomerService } from 'services/customer.service';
import { ToastService } from 'services/toast.service';
import { OrderService } from 'services/order.service';
import { sum } from 'lodash';
import { SitesService } from 'services/sites.service';
import { AuthenticationService } from 'services/authentication.service';
import { NewDistributionCreatedHubService } from 'hubs/new-distribution-created-hub.service';

@Component({
	selector: 'app-customer-distributions',
	templateUrl: './customer-distributions.component.html',
	styleUrls: ['./customer-distributions.component.scss']
})
export class CustomerDistributionsComponent implements OnInit {
	loading: boolean = false;
	loadingCustomers: boolean = false;
	distributionName = '';
	uSelectFilter: USelectType | '' = '';
	pageNo = 1;
	pageSize = 20;
	total = 0;
	isSearchEnabled = false;
	distributionsWithRelatedOrders: CustomerDistributionWithRelatedOrders[] = [];
	USelectType = USelectType;
	customerId = 0;
	customer!: Customer;
	sites: Site[] = [];
	mapType = new Map<USelectType, string>();
	modalRef?: NgbModalRef;
	selectedDistributions: CustomerDistributionWithRelatedOrders[] = [];
	combinedName = '';
	useBusiness = false;
	usePOBoxes = false;
	useApartments = false;
	useHouses = false;
	useFarms = false;
	customers: Customer[] = [];
	selectedCustomer?: Customer;
	selectedSiteId = 0;
	selectedDistribution?: CustomerDistributionWithRelatedOrders;
	distributionToTransfer?: CustomerDistributionWithRelatedOrders;
	focus$ = new Subject<string>();
	deleteAfterTransfer = false;
	includeDeleted = false;
	siteIdFromRoute = 0;
	countryCode: 'US' | 'Canada' = 'US';
	nameSearch = new Subject<string>();
	combineListsQuery?: CombineListQuery;

	@ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;

	constructor(
		public modalService: NgbModal,
		public authService: AuthenticationService,
		private distributionsService: DistributionsService,
		private mapDataService: MapDataService,
		private customerService: CustomerService,
		private orderService: OrderService,
		private sitesService: SitesService,
		private toastService: ToastService,
		private newDistCreatedHub: NewDistributionCreatedHubService,
		private router: Router,
		route: ActivatedRoute
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.siteIdFromRoute = parseInt(route.snapshot.queryParamMap.get('siteId') ?? '0', 10);
		route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			if (this.countryCode === 'Canada') {
				this.mapType.set(USelectType.SnapAdMail, 'Snap AdMail');
				this.mapType.set(USelectType.UploadYourList, 'Uploaded List');
			}
			else {
				this.mapType.set(USelectType.EDDM, 'Every Door Direct Mail');
				this.mapType.set(USelectType.UploadYourList, 'Uploaded List');
				this.mapType.set(USelectType.BuildYourList, 'Consumer List (DBUSA)');
				this.mapType.set(USelectType.BuildPoliticalList, 'Political List (Built)');
				this.mapType.set(USelectType.UploadPoliticalList, 'Political List (Upload)');
				this.mapType.set(USelectType.B2B, 'B2B (Infutor)');
				this.mapType.set(USelectType.EquifaxB2B, 'Business List (Equifax)');
				this.mapType.set(USelectType.ValpakBlueEnvelope, 'Blue Envelopes (Valpak)');
			}


			this.nameSearch.pipe(
				debounceTime(500),
				distinctUntilChanged()
			).subscribe(async value => {
				if (value.length > 0) {
					this.distributionName = value;
				}
				else {
					this.distributionName = '';
				}
				await this.search();
			});

			this.customer = await this.customerService.getCustomer(this.customerId);
            this.sites = await this.sitesService.getSitesForOrganization(this.customer?.organizationId!);
			await this.search();

			this.selectedSiteId = this.siteIdFromRoute > 0 ? this.siteIdFromRoute : this.sites.find(x => x.siteId === this.customer.affiliateID)?.siteId ?? 0;
		}
		catch {
			this.toastService.showError('There was a problem loading distributions', 'Load Error');
		}
		finally {
			this.loading = false;
		}

	}

	async search() {
		this.loading = true;
		try {
			const query = new GetCustomerDistributionsQuery({
				customerId: this.customerId,
				name: this.distributionName,
				uSelect: this.uSelectFilter || undefined,
				showDeleted: this.includeDeleted,
				pagination: new PaginationQuery({
					pageNo: this.pageNo,
					pageSize: this.pageSize
				})
			});
			this.total = await this.distributionsService.getCustomerDistributionsCount(query);
			this.distributionsWithRelatedOrders = await this.orderService.getDistributionsWithRelatedOrders(query);
			if (this.total > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch (error) {
			this.toastService.showError('There was a problem loading distributions', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async clearFilter() {
		this.distributionName = '';
		await this.search();
	}
	async pageChanged() {
		await this.search();
	}

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal, { windowClass: "updateModalClass" });
	}

	isSelected(distribution: CustomerDistributionWithRelatedOrders): boolean {
		return this.selectedDistributions.find(x => x.distributionId === distribution.distributionId) !== undefined;
	}

	selectDistribution(distribution: CustomerDistributionWithRelatedOrders, checked: boolean) {
		if (checked) {
			this.selectedDistributions.push(distribution);
		}
		else {
			const index = this.selectedDistributions.findIndex(x => x.distributionId === distribution.distributionId);
			this.selectedDistributions.splice(index, 1);
		}
	}

	async combineMaps() {
		const uSelectId = this.selectedDistributions[0].uSelect;
		if (uSelectId === USelectType.EDDM) {
			await this.combineEddmMaps();
		}
		if (uSelectId === USelectType.SnapAdMail) {
			await this.combineSnapMaps();
		}
	}

	async combineEddmMaps() {
		this.loading = true;
		try {
			this.closeModal();
			const distributionIds = this.selectedDistributions.map(distribution => distribution.distributionId);
			await this.mapDataService.combineMaps(this.customerId, distributionIds, this.combinedName, this.useBusiness, this.usePOBoxes);
			this.combinedName = '';
			this.useBusiness = false;
			this.usePOBoxes = false;
			this.selectedDistributions = [];
			await this.search();
			this.toastService.showSuccess('Distributions combined successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem combining maps', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async combineSnapMaps() {
		this.loading = true;
		try {
			this.closeModal();
			const distributionIds = this.selectedDistributions.map(distribution => distribution.distributionId);
			const query = {
				combinedName: this.combinedName,
				distributionIds,
				useApartment: this.useApartments,
				useHouse: this.useHouses,
				useFarm: this.useFarms,
				useBusiness: this.useBusiness
			} as CaCombineMapsQuery;
			await this.mapDataService.combineCaMaps(this.customerId, query);
			this.combinedName = '';
			this.useApartments = false;
			this.useHouses = false;
			this.useFarms = false;
			this.useBusiness = false;
			this.usePOBoxes = false;
			this.selectedDistributions = [];
			await this.search();
			this.toastService.showSuccess('Distributions combined successfully');
		}
		catch (err: any) {
			console.log(err);
			this.toastService.showError('There was a problem combining maps', 'Load Error');
		}
		finally {
			this.loading = false;
		}
	}

	async deleteDistribution(distributionId: number) {
		this.loading = true;
		try {
			await this.distributionsService.deleteDistribution(this.customerId, distributionId);
			await this.search();
			this.toastService.showSuccess('Distribution removed successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem deleting the map/list', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async restoreDistribution(distributionId: number) {
		this.loading = true;
		try {
			await this.distributionsService.restoreDistribution(this.customerId, distributionId);
			await this.search();
			this.toastService.showSuccess('Distribution restored successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem restoring the distribution', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	closeModal() {
		this.selectedDistribution = undefined;
		this.selectedSiteId = this.siteIdFromRoute ? this.siteIdFromRoute : this.sites.find(x => x.siteId === this.customer.affiliateID)?.siteId ?? 0;
		this.combineListsQuery = undefined;
		this.modalService.dismissAll();
	}

	async openCustomerSearchModal(modal: any, dist: CustomerDistributionWithRelatedOrders) {
		this.loading = true;
		try {
			const customerQuery = new CustomerSearchMapTransfer();
			customerQuery.pageNo = 1;
			customerQuery.pageSize = 50;
			customerQuery.fromCustomerId = this.customerId;
			this.customers = await this.customerService.searchCustomersForMapTransfer(customerQuery);
			this.distributionToTransfer = dist;
			this.modalRef = this.modalService.open(modal, { windowClass: "updateModalClass" });
		}
		catch (error) {
			this.toastService.showError('There was a problem loading all customers', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	searchCustomers: OperatorFunction<string, readonly Customer[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			switchMap(async term => {
				return await this.searchForCustomer(term);
			})
		);

	customersFormatter = (x: { username: string }) => x.username;

	async searchForCustomer(term: string) {
		this.loadingCustomers = true;
		let query = {
			fromCustomerId: this.customerId,
			toCustomerUsername: term,
			pageNo: 1,
			pageSize: 50
		} as CustomerSearchMapTransfer;
		this.customers = await this.customerService.searchCustomersForMapTransfer(query);
		this.loadingCustomers = false;
		return this.customers;
	}

	setCustomer(customer: any) {
		this.selectedCustomer = customer.item;
	}

	proceedToSiteSelection(distribution: CustomerDistributionWithRelatedOrders, siteModal: any): void {
		this.selectedDistribution = distribution;
		this.modalService.open(siteModal, { windowClass: "updateModalClass" });

	}

	async startOrder(): Promise<void> {
		try {
			this.modalService.dismissAll();
			this.loading = true;
			//EDDM distribution lookup
			switch (this.selectedDistribution?.uSelect) {
				case USelectType.EDDM:
					const eddmDistribution = await this.mapDataService.getEddmDistribution(this.customerId, this.selectedDistribution.distributionId);
					const routeIds = eddmDistribution.carrierRoutes?.map(selection => selection.name!) ?? [];
					const carrierRoutes = await this.mapDataService.fetchRoutes(routeIds);
					const residential = sum(carrierRoutes.map(route => route.rescount));
					const business = this.selectedDistribution.useBusiness ? sum(carrierRoutes.map(route => route.bizcount)) : 0;
					const poBoxes = this.selectedDistribution.usePOBoxes ? sum(carrierRoutes.map(route => route.boxcount)) : 0;
					const total = residential + business + poBoxes;

					if (total === this.selectedDistribution.totalDeliveries) {
						await this.router.navigate([`/customers/${this.customerId}/shop/${this.selectedSiteId}/${this.selectedDistribution.distributionId}`]);
					}
					else {
						await this.router.navigate([`/customers/${this.customerId}/map/${this.selectedDistribution.distributionId}`]);
					}
					break;
				default:
					await this.router.navigate([`/customers/${this.customerId}/shop/${this.selectedSiteId}/${this.selectedDistribution?.distributionId}`]);
					break;
			}
		}
		catch (error) {
			this.toastService.showError('Error fetching routes');
		}
		finally {
			this.loading = false;
		}
	}

	async transferDistribution() {
		this.loading = true;
		const query = {
			distributionId: this.distributionToTransfer!.distributionId,
			targetCustomerId: this.selectedCustomer!.customerID,
			startingCustomerId: this.customerId
		} as MigrateDistributionQuery;
		let success = true;
		this.modalService.dismissAll();
		try {
			await this.distributionsService.transferDistributionToCustomer(query);
		}
		catch (error: any) {
			success = false;
			this.toastService.showError('Copy of distribution failed, reach out to the dev team.');
		}

		if (this.deleteAfterTransfer) {
			try {
				await this.distributionsService.deleteDistribution(query.startingCustomerId, query.distributionId);
			}
			catch (error: any) {
				success = false;
				this.toastService.showError('Copy completed, but delete of original failed. Reach out to the dev team for manual delete.');
			}
		}

		if (success) {
			this.toastService.showSuccess(`Distribution ${this.distributionToTransfer!.name} successfully transfered to customer ${this.selectedCustomer!.username}`);
			this.deleteAfterTransferFalseAgain();
			this.distributionToTransfer = undefined;
			await this.search();
		}
		this.selectedCustomer = undefined;
		this.loading = false;
	}

	deleteAfterTransferFalseAgain() {
		this.deleteAfterTransfer = false;
	}

	async includeDeletedChecked() {
		await this.search();
	}

	siteChanged(siteId: number) {
		this.selectedSiteId = siteId;
	}

	getEddmDeliveryType(distWithOrders: CustomerDistributionWithRelatedOrders): string {
		let deliveryType: string[] = ['Residential'];
		if (distWithOrders.useBusiness) {
			deliveryType.push('Businesses');
		}
		if (distWithOrders.usePOBoxes) {
			deliveryType.push('PO Boxes');
		}
		return deliveryType.join(', ');
	}
	getSnapAdMailDeliveryType(distWithOrders: CustomerDistributionWithRelatedOrders): string {
		let deliveryType: string[] = [];
		if (distWithOrders.useApartment) {
			deliveryType.push('Apartment');
		}
		if (distWithOrders.useFarm) {
			deliveryType.push('Farms');
		}
		if (distWithOrders.useHouse) {
			deliveryType.push('Houses');
		}
		if (distWithOrders.useBusiness) {
			deliveryType.push('Businesses');
		}
		return deliveryType.join(', ');
	}

	selectionsAreValid(): boolean {
		if (this.selectedDistributions.length <= 1) {
			return false;
		}
		if (this.selectedDistributions[0].uSelect !== USelectType.EDDM && this.selectedDistributions[0].uSelect !== USelectType.SnapAdMail) {
			return false;
		}
		const distUSelect = this.selectedDistributions[0].uSelect;
		if (distUSelect === USelectType.EDDM) {
			return this.validEddmSelections();
		}
		else {
			return this.validSnapAdMailSelections();
		}
	}

	validEddmSelections(): boolean {
		const eddmSelectedCount = this.selectedDistributions.filter(x => x.uSelect === USelectType.EDDM).length;
		return this.selectedDistributions.length > 1 && eddmSelectedCount === this.selectedDistributions.length;
	}

	validSnapAdMailSelections(): boolean {
		const snapSelectedCount = this.selectedDistributions.filter(x => x.uSelect === USelectType.SnapAdMail).length;
		return this.selectedDistributions.length > 1 && snapSelectedCount === this.selectedDistributions.length;
	}

	listSelectionsAreValid(): boolean {
		if (this.selectedDistributions.length <= 1) {
			return false;
		}
		if (this.selectedDistributions[0].uSelect !== USelectType.BuildYourList && this.selectedDistributions[0].uSelect !== USelectType.UploadYourList) {
			return false;
		}
		const distUSelect = this.selectedDistributions[0].uSelect;
		if (distUSelect === USelectType.BuildYourList) {
			const builtListCount = this.selectedDistributions.filter(x => x.uSelect === USelectType.BuildYourList).length;
			return this.selectedDistributions.length > 1 && builtListCount === this.selectedDistributions.length;
		}
		else {
			const uploadedListCount = this.selectedDistributions.filter(x => x.uSelect === USelectType.UploadYourList).length;
			return this.selectedDistributions.length > 1 && uploadedListCount === this.selectedDistributions.length;
		}
	}

	async deleteDistributions() {
		try {
			this.modalService.dismissAll();
			this.loading = true;
			const selectedDistributionIds = this.selectedDistributions.map(x => x.distributionId);
			await this.distributionsService.deleteDistributions(this.customerId, selectedDistributionIds);
			this.selectedDistributions = [];
			await this.search();
			this.toastService.showSuccess('Distributions were deleted successfully');
		}
		catch {
			this.toastService.showError('There was a problem loading distributions', 'Load Error');
		}
		finally {
			this.loading = false;
		}
	}

	openCombineListsModal(modal: any) {
		this.combineListsQuery = {
			customerId: this.customerId,
			filename: '',
			uSelect: this.selectedDistributions[0].uSelect
		} as CombineListQuery;
		this.openModal(modal);
	}

	async combineLists() {
		this.loading = true;
		let selectedDists: ListsToCombine[] = [];
		this.selectedDistributions.map(dist => {
			const listToCombine = {
				distributionId: dist.distributionId,
				isSuppressedList: dist.isSuppressedList
			} as ListsToCombine;
			selectedDists.push(listToCombine);
		});
		this.combineListsQuery!.lists = selectedDists;
		let success = true;
		try {
			await this.distributionsService.combineLists(this.combineListsQuery!);
		}
		catch (ex: any) {
			success = false;
			console.log(ex);
			this.toastService.showError(ex.response);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.closeModal();
			this.newDistCreatedHub.newDistributionCreated.subscribe(response => {
				this.toastService.showCustom('newDistCreatedToast', 'information', response, 10000);
			});
			this.toastService.showSuccess('Lists have started to combine');
			await this.search();
		}
	}
}
