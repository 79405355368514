import { Component, Output, EventEmitter, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { CustomerAddress, DefaultAddressQuery, AddressTypes, Customer } from '@taradel/web-api-client';
import { CustomerAddressService } from 'services/customer-address.service';
import { Subject } from 'rxjs';
import { ToastService } from 'services/toast.service';
import { createEmailValidator } from '@taradel/white-label-common';
import { zipCodeValidator } from 'app/utils/zip-code-validator';
import { formatCAPostalCode } from 'app/utils/format-ca-postal-code';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Component({
	selector: 'app-new-address',
	templateUrl: './new-address.component.html',
	styleUrls: ['./new-address.component.scss']
})
export class NewAddressComponent implements OnInit, OnDestroy {
	loading = false;
	addressForm: UntypedFormGroup;
	state = '';
	selectedAddress: CustomerAddress | undefined;
	defaultAddress: CustomerAddress | undefined;
	submitted = false;
	countryCode = this.customerAddressService.countryCode;
	options!: Options;
	preFillText?: string;

	unsubscribe$ = new Subject<void>();

	@ViewChild('placesRef') placesRef!: GooglePlaceDirective;

	@Input() addressType!: string;
	@Input() customerId!: number;
	@Input() addressToPrefill?: CustomerAddress;
	@Input() customer?: Customer;
	@Output() addressSubmitted = new EventEmitter<number>();
	@Output() cancelInput = new EventEmitter<boolean>();

	constructor(
		private customerAddressService: CustomerAddressService,
		private formBuilder: UntypedFormBuilder,
		private toastService: ToastService
	) {
		this.addressForm = this.formBuilder.group({
			company: new UntypedFormControl('', Validators.maxLength(100)),
			firstName: new UntypedFormControl(''),
			lastName: new UntypedFormControl(''),
			googleAddress: new UntypedFormControl(''),
			address1: new UntypedFormControl('', Validators.required),
			address2: new UntypedFormControl(''),
			state: new UntypedFormControl('', Validators.required),
			city: new UntypedFormControl('', Validators.required),
			zipCode: new UntypedFormControl('', Validators.compose([
				Validators.required,
				zipCodeValidator()
			])),
			country: new UntypedFormControl(''),
			phoneNumber: new UntypedFormControl(''),
			emailAddress: new UntypedFormControl('', createEmailValidator({'required': false})),
			liftgate: new UntypedFormControl(false),
			setAsDefault: new UntypedFormControl(true)
		});
	}

	get addressControls() { return this.addressForm.controls; }

	ngOnInit(): void {
		if (this.countryCode === 'Canada') {
			this.options = new Options({ componentRestrictions: { country: 'ca' }});
		}

		if (!!this.addressToPrefill) {
			this.setInitialAddress(this.addressToPrefill);
			this.setInitialValidators();
			this.submitted = true;
		}
		else {
			this.prefillFromCustomerRecord();
			this.setInitialValidators();
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	prefillFromCustomerRecord() {
		if (!this.customer) {
			return;
		}

		switch (this.addressType) {
			case AddressTypes.Return:
				this.addressControls.company.setValue(this.customer.company);
				break;
			case AddressTypes.Shipping:
				this.addressControls.firstName.setValue(this.customer.firstName);
				this.addressControls.lastName.setValue(this.customer.lastName);
				this.addressControls.company.setValue(this.customer.company);
				this.addressControls.phoneNumber.setValue(this.customer.phoneNumber);
				this.addressControls.emailAddress.setValue(this.customer.emailAddress);
				break;
			case AddressTypes.Mailing:
				this.addressControls.firstName.setValue(this.customer.firstName);
				this.addressControls.lastName.setValue(this.customer.lastName);
				this.addressControls.company.setValue(this.customer.company);
				break;
			case AddressTypes.Billing:
				this.addressControls.firstName.setValue(this.customer.firstName);
				this.addressControls.lastName.setValue(this.customer.lastName);
				this.addressControls.company.setValue(this.customer.company);
				this.addressControls.emailAddress.setValue(this.customer.emailAddress);
				this.addressControls.phoneNumber.setValue(this.customer.phoneNumber);
				break;
			default:
				this.addressControls.firstName.setValue(this.customer.firstName);
				this.addressControls.lastName.setValue(this.customer.lastName);
				this.addressControls.emailAddress.setValue(this.customer.emailAddress);
				this.addressControls.phoneNumber.setValue(this.customer.phoneNumber);
				this.addressControls.company.setValue(this.customer.company);
				break;
		}
		this.preFillText = "This form has been prefilled with details from the customer's account.";
	}

	setInitialValidators() {
		switch (this.addressType) {
			case AddressTypes.Return:
				this.addressControls.company.setValidators([Validators.required]);
				this.addressControls.company.updateValueAndValidity();
				break;
			case AddressTypes.Shipping:
				if (this.addressControls.company.value === '') {
					this.addressControls.firstName.setValidators([Validators.required]);
					this.addressControls.lastName.setValidators([Validators.required]);
					this.addressControls.firstName.updateValueAndValidity();
					this.addressControls.lastName.updateValueAndValidity();
				}
				else if (this.addressControls.firstName.value === '') {
					this.addressControls.company.setValidators([Validators.required]);
					this.addressControls.company.updateValueAndValidity();
				}
				this.addressControls.phoneNumber.setValidators([Validators.required]);
				this.addressControls.phoneNumber.updateValueAndValidity();
				break;
			case AddressTypes.Mailing:
				this.addressControls.firstName.setValidators([Validators.required]);
				this.addressControls.lastName.setValidators([Validators.required]);
				this.addressControls.company.setValidators([Validators.required]);
				this.addressControls.firstName.updateValueAndValidity();
				this.addressControls.lastName.updateValueAndValidity();
				this.addressControls.company.updateValueAndValidity();
				break;
			case AddressTypes.Billing:
				this.addressControls.firstName.setValidators([Validators.required]);
				this.addressControls.lastName.setValidators([Validators.required]);
				this.addressControls.phoneNumber.setValidators([Validators.required]);
				this.addressControls.emailAddress.setValidators([Validators.required, createEmailValidator()]);
				this.addressControls.firstName.updateValueAndValidity();
				this.addressControls.lastName.updateValueAndValidity();
				this.addressControls.phoneNumber.updateValueAndValidity();
				this.addressControls.emailAddress.updateValueAndValidity();
				break;
			default:
				if (this.addressControls.company.value === '') {
					this.addressControls.firstName.setValidators([Validators.required]);
					this.addressControls.lastName.setValidators([Validators.required]);
					this.addressControls.firstName.updateValueAndValidity();
					this.addressControls.lastName.updateValueAndValidity();
				}
				else if (this.addressControls.firstName.value === '') {
					this.addressControls.company.setValidators([Validators.required]);
					this.addressControls.company.updateValueAndValidity();
				}
				this.addressControls.phoneNumber.setValidators([Validators.required]);
				this.addressControls.emailAddress.setValidators([Validators.required, createEmailValidator({'required': false})]);
				this.addressControls.phoneNumber.updateValueAndValidity();
				this.addressControls.emailAddress.updateValueAndValidity();
		}
	}

	validateSpecificAddressFields() {
		// Shipping and Mailing only require First Name/Last Name or Company
		if (this.addressControls.company.value !== '') {
			this.addressControls.firstName.clearValidators();
			this.addressControls.lastName.clearValidators();
			this.addressControls.company.addValidators([Validators.required, Validators.maxLength(100)]);
			this.addressControls.firstName.updateValueAndValidity();
			this.addressControls.lastName.updateValueAndValidity();
			this.addressControls.company.updateValueAndValidity();
		}
		else if (this.addressControls.firstName.value !== '' || this.addressControls.lastName.value !== '') {
			this.addressControls.company.clearValidators();
			this.addressControls.firstName.addValidators([Validators.required]);
			this.addressControls.lastName.addValidators([Validators.required]);
			this.addressControls.company.updateValueAndValidity();
			this.addressControls.firstName.updateValueAndValidity();
			this.addressControls.lastName.updateValueAndValidity();

		}
	}

	setInitialAddress(address: CustomerAddress) {
		this.addressControls.firstName.setValue(address.firstName);
		this.addressControls.lastName.setValue(address.lastName);
		this.addressControls.company.setValue(address.company);
		this.addressControls.emailAddress.setValue(address.emailAddress);
		this.addressControls.phoneNumber.setValue(address.phoneNumber);
		this.addressControls.address1.setValue(address.address1);
		this.addressControls.address2.setValue(address.address2);
		this.addressControls.city.setValue(address.city);
		this.addressControls.state.setValue(address.state);
		this.state = address.state!;
		this.addressControls.zipCode.setValue(this.countryCode === 'US' ? address.zipCode : (!!address.zipCode && address.zipCode !== '' ? formatCAPostalCode(address.zipCode) : ''));
		this.addressControls.country.setValue(!!address.country && address.country !== '' ? address.country : (this.countryCode === 'US' ? 'US' : 'CA'));
	}

	cancelEditing() {
		this.cancelInput.emit(true);
	}

	stateSelected(value: string) {
		this.addressControls.state.setValue(value);
	}

	formatPhoneNumber(control: string) {
		let inputValue = this.addressControls[control].value;
		let temp = inputValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		inputValue = !temp[2] ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '');
		this.addressControls[control].setValue(inputValue);
	}

	handleAddressChange(address: Address) {
		let tempAddress = '';
		address.address_components.forEach(x => {
			switch (x.types[0]) {
				case 'street_number': {
					tempAddress = x.long_name;
					break;
				}
				case 'route': {
					tempAddress = tempAddress + " " + x.long_name;
					this.addressControls.address1.setValue(tempAddress);
					break;
				}
				case 'locality': {
					this.addressControls.city.setValue(x.long_name);
					break;
				}
				case 'administrative_area_level_1': {
					this.state = x.short_name;
					this.addressControls.state.setValue(x.short_name);
					break;
				}
				case 'postal_code': {
					this.addressControls.zipCode.setValue(x.long_name);
					break;
				}
				case 'country': {
					this.addressControls.country.setValue(x.short_name);
					break;
				}
			}
		});
		this.addressControls.googleAddress.setValue(tempAddress);
	}

	async submit() {
		this.submitted = true;
		if (this.addressType === AddressTypes.Mailing || this.addressType === AddressTypes.Shipping) {
			this.validateSpecificAddressFields();
		}
		if (this.addressForm.invalid) {
			return;
		}
		try {
			this.loading = true;
			const tempAddress = {
				customerId: this.customerId,
				firstName: this.addressControls.firstName.value,
				lastName: this.addressControls.lastName.value,
				address1: this.addressControls.address1.value,
				address2: this.addressControls.address2.value,
				city: this.addressControls.city.value,
				state: this.addressControls.state.value,
				zipCode: this.countryCode === 'US' ? this.addressControls.zipCode.value : formatCAPostalCode(this.addressControls.zipCode.value),
				country: (this.addressControls.country.value !== '' && !!this.addressControls.country.value) ? this.addressControls.country.value : (this.countryCode === 'US' ? 'US' : 'CA'),
				phoneNumber: this.addressControls.phoneNumber.value,
				company: this.addressControls.company.value,
				emailAddress: this.addressControls.emailAddress.value,
				liftgate: this.addressControls.liftgate.value
			} as CustomerAddress;
			const newAddressId = await this.customerAddressService.addCustomerAddress(tempAddress);

			if (this.addressControls.setAsDefault.value === true) {
				const query = {
					addressId: newAddressId,
					addressType: this.addressType
				} as DefaultAddressQuery;

				try {
					await this.customerAddressService.updateDefaultAddress(this.customerId, query);
				}
				catch (ex: any) {
					console.log(ex);
				}
			}
			this.addressForm.reset();
			this.addressSubmitted.emit(newAddressId);
			this.cancelInput.emit(true);
		}
		catch {
			this.toastService.showError('There was an error adding the address');
		}
		finally {
			this.loading = false;
		}
	}
}
