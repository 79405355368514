<ng-template #orderCreatedToast let-toast>
	<div class="bg-white text-success p-2">
        <div class="d-flex justify-content-between">
            <strong><i class="fa fa-star"></i>&nbsp;&nbsp; New Order Placed</strong>
            <button type="button" class="btn-close m-0 p-0" (click)="toastService.remove(toast)"></button>
        </div>
        <div class="mt-2 text-secondary">
            Order <strong><a class="text-success" [routerLink]="['/orders', toast.data.orderId, 'summary']">{{toast.data.orderId}}</a></strong> was placed on {{toast.data.site}}
            by {{toast.data.customer}} for {{toast.data.amount | currency:'USD'}}!
            <p *ngIf="toast.data.salesRep">Sales Rep: {{toast.data.salesRep}}</p>
            <p *ngIf="!toast.data.salesRep">Method: self-checkout</p>
        </div>
	</div>
</ng-template>

<ng-template #newDistFromSuppressionToast let-toast>
	<div class="bg-white text-gray-600 p-2">
        <div class="d-flex justify-content-between">
            <strong><i class="fa fa-list"></i>&nbsp;&nbsp; New Distribution Created</strong>
            <button type="button" class="btn-close m-0 p-0" (click)="toastService.remove(toast)"></button>
        </div>
        <div class="mt-2 text-secondary">
            Distribution <strong><a class="text-success" [routerLink]="['/customers', toast.data.customerId, 'distributions', toast.data.distributionId]">{{toast.data.distributionId}}</a></strong> was created by suppression
        </div>
	</div>
</ng-template>

<ng-template #newDistCreatedToast let-toast>
	<div class="bg-white text-gray-600 p-2">
        <div class="d-flex justify-content-between">
            <strong><i class="fa fa-list"></i>&nbsp;&nbsp; New Distribution Created</strong>
            <button type="button" class="btn-close m-0 p-0" (click)="toastService.remove(toast)"></button>
        </div>
        <div class="mt-2 text-secondary">
            Distribution <strong><a class="text-success" [routerLink]="['/customers', toast.data.customerId, 'distributions', toast.data.distributionId]">{{toast.data.distributionId}}</a></strong> was created
        </div>
	</div>
</ng-template>

<!-- future templates should go above here -->
<div class="toast-container">
    <ngb-toast *ngFor="let toast of toastService.toasts"
    [ngClass]="toast.options.type"
    [header]="toast.options.header || ''"
    [autohide]="toast.options.type !== 'error'"
    [delay]="toast.options.delay || 5000"
    (hide)="toastService.remove(toast)"
    >
        <ng-template [ngIf]="toast.textOrTpl" [ngIfElse]="text">
            <!-- reference correct ng-template with toast.textOrTpl value -->
            <ng-container *ngTemplateOutlet="{ 'orderCreatedToast': orderCreatedToast, 'newDistFromSuppressionToast': newDistFromSuppressionToast }[toast.textOrTpl] ?? null; context: {$implicit: toast}"></ng-container>
        </ng-template>
        <ng-template #text>{{toast.options.body}}</ng-template>
    </ngb-toast>
</div>
