import { Component, OnInit } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import * as Oidc from 'oidc-client';
import pageMenus, { MenuItem } from 'app/config/page-menus';
import { ThemeSettingsService } from 'services/theme-settings.service';
import * as md5 from 'crypto-js/md5';
import { environment } from 'environment';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	currUserProfile!: Oidc.Profile | undefined;
	isSystemAdmin!: boolean;
	authToken!: string | undefined;
	menus: MenuItem[] = [];
	sidebarState = 'expand';
	navProfileState = 'expand';
	scrollTop = 0;
	env = environment;

	constructor(
		public auth: AuthenticationService,
		private themeSettings: ThemeSettingsService,
		private toastService: ToastService
	) { }

	async ngOnInit(): Promise<void> {
		const user = await this.auth.getUser();
		this.currUserProfile = user?.profile;

		const token = await this.auth.token();
		this.authToken = token.replace('Bearer ', '');

		this.isSystemAdmin = await this.auth.hasRole('SystemAdmin');

		for (let menuItem of pageMenus) {
			if ((menuItem.roles ?? []).length === 0 ||
			(await this.auth.hasAnyRole(menuItem.roles ?? []) &&
			((menuItem.organizations ?? []).length === 0 ||
			menuItem.organizations?.includes(this.auth.organizationId))
			&& !(menuItem.organizationsToExclude?.includes(this.auth.organizationId)))) {

				this.menus.push(menuItem);
			}
		}
	}

	getGravatarLink(): string {
		if (this.currUserProfile?.name !== undefined) {
			const hash = md5(this.currUserProfile.name.trim().toLowerCase());
			return `https://www.gravatar.com/avatar/${hash}`;
		}

		return '';
	}

	displayUserRoles(roles: string | string[]): string {
		if (Array.isArray(roles)) {
			return roles.join(', ');
		}
		else {
			return roles;
		}
	}

	hasVisibleSubmenu(menu: MenuItem): boolean {
		if (!menu.submenu)  {
			return false;
		}
		return menu.submenu?.some(m => !m.hide);
	}

	visibleSubmenus(menu: MenuItem): MenuItem[] {
		if (menu.submenu) {
			return menu.submenu.filter(m => !m.hide);
		}
		return [];
	}

	toggleAppSidebarMinified() {
		this.themeSettings.appSidebarMinifiedToggledEvent.emit(true);
		this.navProfileState = 'collapse';
	}

	toggleNavProfile() {
		if (this.navProfileState === 'collapse') {
			this.navProfileState = 'expand';
		}
		else {
			this.navProfileState = 'collapse';
		}
	}

	expandCollapseSubmenu(currentMenu: MenuItem, allMenu: Array<MenuItem>, active: RouterLinkActive) {
		for (const menu of allMenu) {
			if (menu !== currentMenu) {
				menu.state = 'collapse';
			}
		}
		if (active.isActive) {
			currentMenu.state = (currentMenu.state && currentMenu.state === 'collapse') ? 'expand' : 'collapse';
		}
		else {
			currentMenu.state = (currentMenu.state && currentMenu.state === 'expand') ? 'collapse' : 'expand';
		}

		// get the active menu items parent path, and expand those menu items as well

	}

	async launchSwagger(url: string | undefined) {
		const bearerToken = await this.refreshAuthToken();
		const toast = this.toastService;
		navigator.clipboard
			.writeText(bearerToken)
			.then(
				() => toast.showInfo('Your bearer token has been copied to your clipboard. You may Ctl+V the value in swagger to authenticate.'),
				() => toast.showError('There was a problem copying your bearer token to the clipboard, but you will be sent to swagger anyway')
			)
			.then(() => window.open(url + '/swagger'));

	}

	async launchHangfire() {
		const bearerToken = await this.refreshAuthToken();
		const env = this.env;
		window.open(env?.webApiUrl + '/hangfire/?access_token=' + bearerToken.replace('Bearer ', ''));
	}

	async refreshAuthToken(): Promise<string> {
		return await this.auth.token();
	}
}
