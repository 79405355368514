import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormArray } from '@angular/forms';
import { PaymentType, CustomerPaymentDisplay, PartialRefundRequest, OrderRefundQuery, RefundPaymentRequest } from '@taradel/admin-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { OrderService } from 'services/order.service';
import { ToastService } from 'services/toast.service';
import { sumBy } from 'lodash';
import { PaymentsService } from 'services/payments.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


export interface RefundOrder {
	orderId: number;
	orderBalance: number;
	siteName?: string;
	paidAmount: number;
	refundAmount: number;
}

@Component({
	selector: 'app-refund-payment',
	templateUrl: './refund-payment.component.html',
	styleUrls: ['./refund-payment.component.scss']
})
export class RefundPaymentComponent implements OnInit {
	customerId?: number;
	customerPaymentId: number;
	customerPayment?: CustomerPaymentDisplay;
	refundOrders: RefundOrder[] =[];
	unappliedCustomerPaymentAmount: number = 0;
	loading = false;
	createRefundForOrders?: boolean;
	isSalesAdmin = false;
	PaymentType = PaymentType;
	paymentRefPlaceholder = '';
	paymentReferenceForm: FormGroup;
	selectAllRefunds = false;

	// all the forms
	refundForm = this.formBuilder.group({
		refundTotal: new FormControl(0, Validators.required),
		includeRefundFromCustomerPayment: new FormControl(false),
		refundFromCustomerPayment: new FormControl(undefined),
		includeOrders: new FormControl(true),
		refundPayments: this.formBuilder.array([]),
		note: new FormControl(undefined, Validators.maxLength(255)),
		paymentType: new FormControl(undefined)
	});

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthenticationService,
		private modalService: NgbModal,
		private orderService: OrderService,
		private paymentService: PaymentsService,
		private toastService: ToastService,
		private formBuilder: UntypedFormBuilder
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.customerPaymentId = parseInt(route.snapshot.paramMap.get('customerPaymentId') ?? '0', 10);

		this.paymentReferenceForm = this.formBuilder.group({
			paymentRef: new UntypedFormControl(undefined, Validators.required),
		});
	}

	get control() {
		return this.refundForm.controls;
	}

	get refundPayments() {
		return (this.refundForm.controls['refundPayments'] as FormArray).controls as FormGroup[];
	}
	get paymentRefControl() {
		return this.paymentReferenceForm.controls;
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		try {
			//TODO: parallelize these requests
			this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
			this.customerPayment = await this.paymentService.getCustomerPayment(this.customerPaymentId);
			const refPaymentsOrderPayments = await this.orderService.getReferencingOrderPayments(this.customerPaymentId);
			const orderPaymentsLinkedToCP = await this.orderService.getAssociatedOrderPayments(this.customerPaymentId);
			let totalPaidAmount = 0;
			this.refundOrders = [];
			for (let index = 0; index < orderPaymentsLinkedToCP.length; index++) {
				const op = orderPaymentsLinkedToCP[index];
				const t = refPaymentsOrderPayments.filter(x => x.orderId === op.orderId);
				let refPaymentTotal = 0;
				t.map(x => {
						refPaymentTotal += x.orderPayments?.reduce((p, current) => p + current.amount, 0) ?? 0;
				});
				const paidAmount = Math.round(sumBy(op.orderPayments, (opp) => opp.amount * 100)) / 100;
				totalPaidAmount += paidAmount;
				if (paidAmount <= -refPaymentTotal) {
					// Already refunded
					continue;
				}
				else {
					const amountToRefund = Math.round((paidAmount + refPaymentTotal) * 100) / 100;
					this.refundOrders.push({orderId: op.orderId, orderBalance: op.orderBalance, paidAmount: refPaymentTotal, refundAmount: amountToRefund, siteName: op.siteName});
				}

			}
			if (this.refundOrders.length > 0) {
				this.createRefundForOrders = true;
			}
			this.initializeRefundPayments();
			this.unappliedCustomerPaymentAmount = this.customerPayment.paymentAmount - totalPaidAmount;
			this.refundForm.controls.refundFromCustomerPayment.addValidators(Validators.required);
			this.refundForm.controls.refundFromCustomerPayment.addValidators(this.refundAmountValidator(this.unappliedCustomerPaymentAmount));
		}
		catch (err: any) {
			this.toastService.showError('Could not load payment information');
		}
		finally {
			this.loading = false;
		}
	}

	initializeRefundPayments() {
		(this.refundForm.controls['refundPayments'] as FormArray).controls = [];
		this.refundForm.controls.paymentType.setValue(this.customerPayment?.paymentType);
		this.refundForm.controls.refundFromCustomerPayment.disable();
		if (this.createRefundForOrders) {
			this.loading = true;
			this.refundOrders?.forEach(b => {
				const refundPayment = this.formBuilder.group({
					orderId: new UntypedFormControl(b.orderId, Validators.required),
					siteName: new UntypedFormControl(b.siteName, Validators.required),
					createDate: new UntypedFormControl(),
					orderBalance: new UntypedFormControl(b.orderBalance),
					paymentAmount: new UntypedFormControl(b.refundAmount),
					refundAmount: new UntypedFormControl(b.refundAmount.toFixed(2), Validators.compose([Validators.required, this.refundAmountValidator(b.refundAmount)])),
					selected: new UntypedFormControl(false)
				});
				this.refundPayments.push(refundPayment);
			});
			this.loading = false;
		}
		else {
			this.control.refundTotal.setValue(0);
		}
	}

	getTotalRefundAmount(): number {
		let total = 0;
		if (this.refundPayments.length === 0) {
			return this.control.refundTotal.value;
		}
		this.refundPayments.forEach(payment => {
			const refundPayment = payment as FormGroup;
			const selected = refundPayment.get('selected')?.value;
			const refundAmount = parseFloat(refundPayment.get('refundAmount')?.value);
			total += selected ? refundAmount : 0;
		});

		total += this.getUnappliedRefundAmount();

		return total;
	}

	getSelectedRefunds(showErrorToast: boolean = false): Array<{ orderId: number, refundAmount: number }> {
		const selectedRefunds: Array<{ orderId: number, refundAmount: number }> = [];
		this.refundForm.controls.refundPayments.updateValueAndValidity();
		if (!this.refundForm.valid) {
			if (showErrorToast) {
				this.toastService.showError('Refund form is invalid');
			}
			return selectedRefunds;
		}
		if (this.refundPayments.length > 0) {
			this.refundPayments.forEach(x => {
				if (x.controls.selected.value === true) {
					selectedRefunds.push({
						orderId: x.controls.orderId.value,
						refundAmount: x.controls.refundAmount.value
					});
				}
			});
		}
		else {
			// we're viewing a payment that hasn't been applied to any orders
			selectedRefunds.push({
				orderId: 0,
				refundAmount: parseFloat(this.control.refundTotal.value),
			});
		}
		return selectedRefunds;
	}

	getUnappliedRefundAmount(): number {
		return parseFloat(this.refundForm.controls.refundFromCustomerPayment.value) || 0;
	}

	disableRefundAmountInput(index: number) {
		const refundPayment = this.refundPayments.at(index) as FormGroup;
		if (refundPayment.get('selected')?.value === false) {
			refundPayment.get('refundAmount')?.disable();
		}
		else {
			refundPayment.get('refundAmount')?.enable();
		}
	}

	toggleRefundFromCustomerPayment(forceToValue: boolean | undefined = undefined) {
		// this section seems so verbose, could this be handled with better event bindings in the template?
		this.refundForm.controls.includeRefundFromCustomerPayment.setValue(forceToValue ?? !this.refundForm.controls.includeRefundFromCustomerPayment.value);
		if (this.refundForm.controls.includeRefundFromCustomerPayment.value) {
			this.refundForm.controls.refundFromCustomerPayment.enable();
			if (!this.refundForm.controls.refundFromCustomerPayment.value) {
				this.refundForm.controls.refundFromCustomerPayment.setValue(this.unappliedCustomerPaymentAmount.toFixed(2));
			}
		}
 		else {
			this.refundForm.controls.refundFromCustomerPayment.disable();
		}
	}

	refundAmountValidator(amount: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			// floating point wonkiness
			return (control.value * 100 <= Math.ceil(amount * 100) && control.value) ? null : { invalidRefundAmount: true };
		};
	}

	async refund() {
		if (this.getTotalRefundAmount() === 0) {
			this.toastService.showError('Cannot issue a refund with no refund information entered');
			return;
		}

		const refundsQuery = this.getSelectedRefunds(true).map((refund) => new OrderRefundQuery(refund));

		try {
			this.loading = true;
			const promises = [];
			this.closeModal();

			if (refundsQuery.length) {
				const request = new PartialRefundRequest({
					customerPaymentId: this.customerPaymentId,
					paymentType: this.control.paymentType.value ?? PaymentType.CreditCard,
					note: this.control.note.value,
					paymentRef: this.paymentRefControl.paymentRef.value,
					refunds: refundsQuery
				});
				promises.push(this.paymentService.partialRefund(request));
			}

			if (this.refundForm.controls.includeRefundFromCustomerPayment.value) {
				const cpRefund = new RefundPaymentRequest({
					customerPaymentId: this.customerPaymentId,
					paymentType: this.control.paymentType.value ?? PaymentType.CreditCard,
					note: this.control.note.value,
					paymentRef: this.paymentRefControl.paymentRef.value,
					refundAmount: this.getUnappliedRefundAmount(),
				});
				promises.push(this.paymentService.refundCustomerPayment(cpRefund));
			}

			await Promise.all(promises);
			await this.ngOnInit();
			this.toastService.showSuccess('Refund processed successfully');
			await this.redirectToCustomerPayments();
		}
		catch (err: any) {
			console.log(err);
			if (err.status === 400) {
				this.toastService.showError(err.response);
			}
			else {
				this.toastService.showError('Refund could not be processed');
			}
		}
		finally {
			this.loading = false;
		}
	}
	selectPaymentType(type: PaymentType) {
		this.control.paymentType.setValue(type);
		if (type !== PaymentType.CreditCard) {
			switch (type) {
				case PaymentType.Check:
					this.paymentRefPlaceholder = 'Enter check number';
					break;
				case PaymentType.Wire:
					this.paymentRefPlaceholder = 'Enter wire number';
					break;
				case PaymentType.Chargeback:
					this.paymentRefPlaceholder = 'Enter chargeback ref number';
					break;
				default:
					this.toastService.showError('Unaccepted payment type');
					break;
			}
		}
		else {
			this.paymentRefPlaceholder = '';
		}
	}

	handleSelectAllRefunds() {
		this.selectAllRefunds = !this.selectAllRefunds;
		if (this.selectAllRefunds) {
			this.refundPayments.map(refund => refund.get('selected')?.setValue(true));
			if (this.unappliedCustomerPaymentAmount) {
				this.toggleRefundFromCustomerPayment(true);
				this.refundForm.controls.includeRefundFromCustomerPayment.setValue(true);
			}
		}
		else {
			this.refundPayments.map(refund => refund.get('selected')?.setValue(false));
			if (this.unappliedCustomerPaymentAmount) {
				this.toggleRefundFromCustomerPayment(false);
				this.refundForm.controls.includeRefundFromCustomerPayment.setValue(false);
			}
		}
	}

	async redirectToCustomerPayments() {
		await this.router.navigate([`/customers/${this.customerId}/payments`], { state: { tabId: 'customerPaymentsTab' } });
	}

	openModal(modal: any) {
		this.modalService.open(modal, { windowClass: "updateModalClass" });
	}

	closeModal() {
		this.modalService.dismissAll();
	}
}
