import { Injectable } from '@angular/core';
import { BundlesClient, Bundle, BundleItem, SiteBundle, PaginationQuery, OrderProduct, BundleDiscountType, BundleDisplay,
	BundleItemDisplay, GetBundlesRequest, UpdateBundleRequest, BundleContentResponse, BundleSortOrder
 } from '@taradel/admin-api-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BundlesService {

	private readonly bundlesClient: BundlesClient;

	constructor(http: HttpClient) {
		this.bundlesClient = new BundlesClient(http, environment.adminApiUrl);
	}

	async getSiteBundlesForAdmin(siteId: number): Promise<SiteBundle[]> {
		return await lastValueFrom(this.bundlesClient.getSiteBundlesForAdmin(siteId));
	}

	async getBundle(bundleId: number): Promise<BundleDisplay> {
		return await lastValueFrom(this.bundlesClient.getBundleDisplay(bundleId));
	}

	async searchForSiteBundlesCount(request: GetBundlesRequest): Promise<number> {
		return await lastValueFrom(this.bundlesClient.searchForSiteBundlesCount(request));
	}

	async searchForSiteBundles(request: GetBundlesRequest): Promise<BundleDisplay[]> {
		return await lastValueFrom(this.bundlesClient.searchForSiteBundles(request));
	}

	async createBundle(request: UpdateBundleRequest): Promise<number> {
		return await lastValueFrom(this.bundlesClient.createBundle(request));
	}

	async updateBundle(request: UpdateBundleRequest): Promise<number> {
		return await lastValueFrom(this.bundlesClient.updateBundle(request));
	}

	async deleteBundle(bundleId: number): Promise<number> {
		return await lastValueFrom(this.bundlesClient.deleteBundle(bundleId));
	}

	async updateBundleSortOrder(sortOrder: BundleSortOrder[]): Promise<void> {
		await lastValueFrom(this.bundlesClient.updateBundleSortOrder(sortOrder));
	}

	async getAvailableBundlesForSite(siteId: number): Promise<BundleDisplay[]> {
		return await lastValueFrom(this.bundlesClient.getAvailableBundlesForSite(siteId));
	}

	async createSiteBundle(siteBundle: SiteBundle): Promise<number> {
		return await lastValueFrom(this.bundlesClient.createSiteBundle(siteBundle));
	}

	async getBundleContent(bundleId: number): Promise<BundleContentResponse> {
		return await lastValueFrom(this.bundlesClient.getBundleContent(bundleId));
	}

	async deleteSiteBundle(siteId: number, bundleId: number): Promise<number> {
		return await lastValueFrom(this.bundlesClient.deleteSiteBundle(siteId, bundleId));
	}
}
