import { Component, OnInit } from '@angular/core';
import { ExtendedOrderItem, OrdersQuery, PaginationQuery } from '@taradel/admin-api-client';
import { ColDef, ColGroupDef, GridApi, SideBarDef, SizeColumnsToContentStrategy } from 'ag-grid-enterprise';
import * as moment from 'moment';
import { OrderService } from 'services/order.service';
import { ReportsService } from 'services/reports.service';
import { ToastService } from 'services/toast.service';


interface OrderBalanceReportComputedColumns {
	dayOfWeek: string
	isWeekend: boolean
	weekOfYear: number
}

type OrderBalanceReportComputed = ExtendedOrderItem & OrderBalanceReportComputedColumns

@Component({
	selector: 'app-order-balance-report',
	templateUrl: './order-balance-report.component.html',
	styleUrls: ['./order-balance-report.component.scss'],
})
export class OrderBalanceReportComponent implements OnInit {
	loading = false;
	gridApi: GridApi | undefined;
	paramStartDate: Date = new Date();
	paramEndDate: Date = new Date();
	paramPartialCompanyName: string = '';
	apiQuery: OrdersQuery = new OrdersQuery();

	agThemeClass = 'ag-theme-quartz';

	rowData: OrderBalanceReportComputed[] = [];

	/* ag-grid configuration */
	agDefaultColDef: any = {
		flex: 1,
		allowedAggFuncs: ['sum', 'avg', 'max', 'min', 'count', 'first'],
	};
	agColumnTypes = {
		currency: {
			valueFormatter: currencyFormatter,
		},
	};
	agColumnDefs: ColDef[] | ColGroupDef[] | any = [
		{ field: 'orderID', enableRowGroup: true, rowGroup: true, filter: true, aggFunc: 'first' },
		{ field: 'orderItemId', headerName: 'Order Item ID', hide: true, enableRowGroup: true, filter: true, aggFunc: 'first' },
		{ field: 'customerID', enableRowGroup: true, aggFunc: 'first' },
		{ field: 'customerName', filter: true, enableRowGroup: true, aggFunc: 'first' },
		{ field: 'customerEmailAddress', filter: true, enableRowGroup: true, aggFunc: 'first' },
		{ field: 'customerJobDescription', filter: true, enableRowGroup: true, hide: true, aggFunc: 'first' },
		{ field: 'productName', filter: true, enableRowGroup: true, hide: true, aggFunc: 'first' },
		{ field: 'orderDate', enableRowGroup: true, aggFunc: 'first' },

		// metric fields
		{ field: 'orderAmt', headerName: 'Order Amount', type: 'currency', aggFunc: 'max', cellClass: 'ag-right-aligned-cell' },
		{ field: 'paidAmt', headerName: 'Paid Amount', type: 'currency', aggFunc: 'max', cellClass: 'ag-right-aligned-cell' },
		{
			field: 'balanceDue',
			type: 'currency',
			sort: 'desc',
			aggFunc: 'max',
			cellClass: 'ag-right-aligned-cell',
			cellStyle: (params: any) => {
				if (params.value > 0) {
					return { color: 'red' };
				}
				if (params.value <= 0) {
					return { color: 'green' };
				}
				return null;
			},
			filter: 'agNumberColumnFilter',
			filterParams: {
				buttons: ['reset'],
				defaultOption: 'greaterThan',
				filter: 0,
			},
		},
	];
	agAutoGroupColumnDef = {
		headerName: 'Group By',
		minWidth: 240,
	};
	agSideBar: SideBarDef = {
		toolPanels: ["columns", "filters"],
		defaultToolPanel: "columns",
		hiddenByDefault: false,
		position: "right",
	};
	agColumnsToolPanel = {
		suppressRowGroups: true,
	};
	agStatusBar = {
		statusPanels: [
			{ statusPanel: 'agTotalRowCountComponent' },
			{ statusPanel: 'agFilteredRowCountComponent' },
		]
	};
	agAutoSizeStrategy: SizeColumnsToContentStrategy = {
		type: 'fitCellContents',
		skipHeader: false,
		colIds: undefined,
	};
	/* end ag-grid */

	constructor(
		private orderService: OrderService,
		private toastService: ToastService,
	) { }

	ngOnInit(): void {
		this.rowData.splice(0, this.rowData.length);
		this.paramStartDate = new Date(this.paramStartDate.getFullYear(), this.paramStartDate.getMonth(), 1);
		this.paramStartDate.setMonth(this.paramStartDate.getMonth() - 1);
		this.paramEndDate = new Date(this.paramEndDate.getFullYear(), this.paramEndDate.getMonth() + 1, 0);
		this.apiQuery.fromDate = this.paramStartDate;
		this.apiQuery.toDate = this.paramEndDate;
		this.apiQuery.printerStatus = '';
	}

	async submit() {
		try {
			this.loading = true;
			this.apiQuery.fromDate = this.paramStartDate;
			this.apiQuery.toDate = this.paramEndDate;
			this.apiQuery.pagination = new PaginationQuery({
				pageNo: 1,
				pageSize: 20000,
			});
			const data = await this.orderService.getOrders(this.apiQuery);
			this.rowData = this.annotateWithComputedColumns(data);
		}
		catch (err) {
			this.toastService.showError('There was an error generating and downloading the Order Balance Report');
		}
		finally {
			this.loading = false;
		}
	}

	onGridReady(params: any) {
		this.gridApi = params.api;
	}

	annotateWithComputedColumns(data: ExtendedOrderItem[]): OrderBalanceReportComputed[] {
		const computedData: OrderBalanceReportComputed[] = data.map((origRow: ExtendedOrderItem) => {
			const row = origRow as OrderBalanceReportComputed;
			const date = moment(row.orderDate);
			row.dayOfWeek = date.format("ddd");
			row.isWeekend = (row.dayOfWeek === "Sun" || row.dayOfWeek === "Sat");
			row.weekOfYear = date.week();
			return row;
		});
		return computedData;
	}

	onFirstDataRendered(event: any) {
		this.gridApi?.setFilterModel({
			'balanceDue': {
				type: 'greaterThan',
				filter: 0,
			}
		});
		this.gridApi?.onFilterChanged();
	}

	getDate(input: string): Date {
		const [year, month, day] = input.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}

}

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

function currencyFormatter(params: any) {
	return formatter.format(params.value);
}
