import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Order, Coupon } from '@taradel/web-api-client';
import { ChangeOrdersService } from 'services/change-orders.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-update-order-coupon',
  templateUrl: './update-order-coupon.component.html',
  styleUrls: ['./update-order-coupon.component.scss']
})
export class UpdateOrderCouponComponent implements OnInit {
	loading = false;
	submitted = false;
	orderId?: number;
	couponForm: FormGroup;
	originalCouponCode?: string;
	coupon?: Coupon;
	couponMessage?: string | undefined;
	couponDiscountAmt?: number;

	@Input() order?: Order;
	@Output() updateChangeOrderForm = new EventEmitter<boolean>();

	constructor(private formBuilder: UntypedFormBuilder,
		private changeOrdersService: ChangeOrdersService,
		public orderLayoutService: OrderlayoutService,
		private toastService: ToastService
	) {
		this.couponForm = this.formBuilder.group({
			originalCouponCode: new FormControl<string>(''),
			markOriginalCouponAsDeleted: new FormControl<boolean>(false),
			couponCodeToValidate: new FormControl<string>('', Validators.minLength(1)),
			newCouponCode: new FormControl<string>('', Validators.minLength(1))
		});
	}

	get c() {
		return this.couponForm.controls;
	}

	ngOnInit(): void {
		if (!!this.order) {
			this.orderId = this.order.orderId;
			if (this.order.couponCode && this.order.couponCode.length > 0) {
				this.c.originalCouponCode.setValue(this.order.couponCode);
				const couponDiscount = this.order.couponDiscount;
				this.couponDiscountAmt = couponDiscount < 0 ? couponDiscount : couponDiscount * -1;
			}
		}
	}

	updateOriginalCoupon() {
		const deleted = this.c.markOriginalCouponAsDeleted.value;
		this.c.markOriginalCouponAsDeleted.setValue(!deleted);
		if (this.c.markOriginalCouponAsDeleted.value === false) {
			this.c.newCouponCode.setValue('');
		}
	}

	clearNewCouponCode() {
		this.c.newCouponCode.setValue('');
		if (this.c.originalCouponCode.value.length > 0) {
			this.updateOriginalCoupon();
		}
	}

	async validateCoupon() {
		const couponCode = this.c.couponCodeToValidate.value;
		if (couponCode.length <= 0) {
			this.submitted = true;
			return;
		}
		this.loading = true;
		try {
			this.coupon = await this.changeOrdersService.validateOrderCoupon(this.orderId!, couponCode);
		}
		catch (ex: any) { }
		finally {
			this.loading = false;
		}
		if (this.coupon?.couponDiscount?.isValid) {
			this.c.newCouponCode.setValue(couponCode);
			this.c.couponCodeToValidate.setValue('');
			if (this.c.markOriginalCouponAsDeleted.value === false) {
				this.updateOriginalCoupon();
			}
			const couponDiscount = this.coupon.couponDiscount.discountAmount;
			this.couponDiscountAmt = couponDiscount < 0 ? couponDiscount : couponDiscount * -1;
		}
	}

	async addCoupon() {
		const couponCode = this.c.newCouponCode.value;
		if (couponCode.length <= 0) {
			this.submitted = true;
			return;
		}
		this.loading = true;
		try {
			await this.changeOrdersService.updateOrderCoupon(this.orderId!, couponCode);
			this.resetCouponForm();
			this.c.originalCouponCode.setValue(couponCode);
			const couponDiscount = this.coupon!.couponDiscount!.discountAmount;
			this.couponDiscountAmt = couponDiscount < 0 ? couponDiscount : couponDiscount * -1;
			this.updateChangeOrderForm.emit(true);
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError(`${couponCode} coupon could not be added to this order`);
		}
		finally {
			this.loading = false;
		}
	}

	async removeCoupon() {
		const couponCode = this.c.originalCouponCode.value ?? '';
		if (couponCode.length <= 0) {
			return;
		}
		this.loading = true;
		try {
			await this.changeOrdersService.removeCouponFromOrder(this.orderId!, couponCode);
			this.resetCouponForm();
			this.couponDiscountAmt = undefined;
			this.updateChangeOrderForm.emit(true);
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError(`${couponCode} coupon could not be removed from the order`);
		}
		finally {
			this.loading = false;
		}
	}

	async saveChanges() {
		try {
			if (this.c.newCouponCode.value.length > 0) {
				await this.addCoupon();
			}
			else if (this.c.markOriginalCouponAsDeleted.value === true && this.c.newCouponCode.value.length <= 0) {
				await this.removeCoupon();
			}
		}
		catch (ex: any) {
			console.log(ex);
		}
	}

	resetCouponForm() {
		this.c.originalCouponCode.setValue('');
		this.c.newCouponCode.setValue('');
		this.c.couponCodeToValidate.setValue('');
		this.c.markOriginalCouponAsDeleted.setValue(false);
		this.coupon = undefined;
	}
}
