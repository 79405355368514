import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BundleDisplay, GetBundlesRequest, PaginationQuery, SiteBundle, BundleDiscountType, BundleSortOrder, ApiException, BundleContentResponse, CmsContent } from '@taradel/admin-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { BundlesService } from 'services/bundles.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface BundleObject {
	bundleDisplay: BundleDisplay;
	selected: boolean;
    isAdminOnly: boolean;
}

@Component({
  selector: 'app-all-bundles',
  templateUrl: './all-bundles.component.html',
  styleUrls: ['./all-bundles.component.scss']
})
export class AllBundlesComponent implements OnInit {
	siteId = -1;
	bundles?: BundleObject[];
	selectedBundle?: BundleDisplay;
	selectedBundleContentResponse?: BundleContentResponse;
	selectedBundleContent: CmsContent[] = [];
	originalBundle?: BundleDisplay;
	count = 0;
	loading = false;
	isSalesAdmin = false;
	displayBundle = false;
	customizeBundle = false;
	request = new GetBundlesRequest();
	paginationQuery = new PaginationQuery();
	bundlesNotSubscribedTo: BundleObject[] = [];
	bundlesToSubscribeTo: number[] = [];
	modalRef: any;
	bundleDetailsToDisplay?: BundleDisplay;
	BundleDiscountType = BundleDiscountType;

	constructor(
		public authService: AuthenticationService,
		private bundlesService: BundlesService,
		private sitesService: SitesService,
		private toastService: ToastService,
		private modalService: NgbModal,
		private route: ActivatedRoute
	) {
	}

	async ngOnInit(): Promise<void> {
		this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
		this.route.paramMap.subscribe(async params => {
			this.siteId = parseInt(params.get('siteId') ?? '0', 10);

			await this.sitesService.setCurrentSiteId(this.siteId);

			this.siteId = parseInt(this.route.snapshot.paramMap.get('siteId') ?? '-1', 10);
			this.selectedBundle = undefined;
			this.selectedBundleContentResponse = undefined;
			this.loading = true;
			this.request.name = '';
			this.request.includeDeleted = false;
			this.request.products = [];
			this.request.siteIds = [];
			this.paginationQuery.pageNo = 1;
			this.paginationQuery.pageSize = 20;
			this.request.pagination = this.paginationQuery;

			await this.getBundles(this.siteId ?? 0);
			if (this.siteId > 0) {
				await this.getNonSiteBundles(this.siteId ?? 0);
			}
			this.loading = false;
		});
	}

	async getNonSiteBundles(siteId: number) {
		this.bundlesNotSubscribedTo = [];
		const bundlesNotSubscribedTo = await this.bundlesService.getAvailableBundlesForSite(siteId);
		bundlesNotSubscribedTo.forEach(b => {
			const bundleObj = {
				bundleDisplay: b,
				selected: false,
                isAdminOnly: false
			} as BundleObject;
			this.bundlesNotSubscribedTo.push(bundleObj);
		});
	}

	async getBundles(siteId: number) {
		if (siteId > 0) {
			this.request.siteIds = [siteId];
		}
		const bundleDisplays = await this.bundlesService.searchForSiteBundles(this.request);
		this.bundles = [];
		bundleDisplays.forEach(bundle => {
			const bundleObject = {
				bundleDisplay: bundle,
				selected: false,
                isAdminOnly: bundle.isAdminOnly
			} as BundleObject;
			this.bundles?.push(bundleObject);
		});

		this.count = await this.bundlesService.searchForSiteBundlesCount(this.request);
	}

	moveBundleUp(index: number) {
		this.bundles?.splice(index - 1, 2, this.bundles[index], this.bundles[index - 1]);
	}

	moveBundleDown(index: number) {
		this.bundles?.splice(index, 2, this.bundles[index + 1], this.bundles[index]);
	}

	async saveSortOrder() {
		let sortOrder: BundleSortOrder[] = [];
		for (let i = 0; i < this.bundles!.length; i++) {
			sortOrder.push(new BundleSortOrder({
				bundleId: this.bundles![i].bundleDisplay.bundleId,
				sortOrder: i
			}));
		}

		this.loading = true;
		try {
			await this.bundlesService.updateBundleSortOrder(sortOrder);
			this.toastService.showSuccess("Sort order updated successfully");
		}
		catch (err) {
			if (ApiException.isApiException(err)) {
				this.toastService.showError(err.message);
			}
			else {
				this.toastService.showError("Error updating sort order");
			}
		}
		finally {
			this.loading = false;
		}
	}

	async selectBundle(bundle: BundleObject) {
		if (bundle.selected) {
			this.deselectBundle();
			this.displayBundle = false;
		}
		else {
			this.deselectBundle();
			if (!!this.bundles) {
				this.loading = true;
				const index = this.bundles.findIndex(b => b.bundleDisplay.bundleId === bundle.bundleDisplay.bundleId);
				this.bundles[index].selected = true;
				this.selectedBundle = bundle.bundleDisplay;
				this.selectedBundleContentResponse = await this.bundlesService.getBundleContent(this.selectedBundle.bundleId);
				if (!!this.selectedBundleContentResponse) {
					this.selectedBundleContentResponse.bundleCmsContent?.forEach(b => {
						if (b.cmsContent && b.cmsContent.length) {
							b.cmsContent.forEach(c => this.selectedBundleContent?.push(c));
						}
					});
				}
				this.originalBundle = bundle.bundleDisplay;
				this.displayBundle = true;
				this.loading = false;
			}
		}
	}

	deselectBundle() {
		if (!!this.bundles) {
			this.bundles.forEach(b => b.selected = false);
			this.selectedBundle = this.originalBundle;
			this.selectedBundle = undefined;
			this.selectedBundleContentResponse = undefined;
			this.selectedBundleContent = [];
			this.originalBundle = undefined;
		}
		this.cancelBundleEditingWizard();
		this.displayBundle = false;
	}

	startBundleEditingWizard() {
		this.displayBundle = false;
		this.customizeBundle = true;
	}

	cancelBundleEditingWizard() {
		this.customizeBundle = false;
		this.displayBundle = true;
	}

	addNewBundle() {
		this.deselectBundle();
		const bundle = new BundleDisplay();
		this.selectedBundle = bundle;
		this.startBundleEditingWizard();
	}

	async handleNewBundleAdded(bundleId: number) {
		this.deselectBundle();
		await this.getBundles(this.siteId);
		const selectedBundle = this.bundles?.find(b => b.bundleDisplay.bundleId === bundleId);
		if (!!selectedBundle) {
			this.selectBundle(selectedBundle);
		}
	}

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal, { windowClass: '', size: 'lg', scrollable: true });
	}

	closeModal() {
		this.bundlesToSubscribeTo = [];
		this.bundleDetailsToDisplay = undefined;
		this.modalService.dismissAll();
	}

	manageListToBundlesToSubscribeTo(bundle: BundleObject) {
		bundle.selected = !bundle.selected;
		if (bundle.selected === true) {
			this.bundlesToSubscribeTo.push(bundle.bundleDisplay.bundleId);
		}
		else {
			const index = this.bundlesToSubscribeTo.findIndex(b => b === bundle.bundleDisplay.bundleId);
			if (index > -1) {
				this.bundlesToSubscribeTo.splice(index, 1);
			}
		}
	}
    

	updateAdminOnlyFlagForBundle(bundle: BundleObject) {
		bundle.isAdminOnly = !bundle.isAdminOnly;
	}

	async subscribeSiteToBundles() {
		this.loading = true;
		let success = true;
		try {
			for (let i = 0; i < this.bundlesToSubscribeTo.length; i++) {
                const bundle = this.bundlesNotSubscribedTo.find(x => x.bundleDisplay.bundleId === this.bundlesToSubscribeTo[i]);
				const siteBundle = {
					siteId: this.siteId,
					bundleId: this.bundlesToSubscribeTo[i],
                    isAdminOnly: bundle?.isAdminOnly ?? false
				} as SiteBundle;
				await this.bundlesService.createSiteBundle(siteBundle);
			}
		}
		catch (ex: any) {
			success = false;
			console.log(ex);
			this.toastService.showError('Unable to subscribe site to bundle(s)');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.closeModal();
			this.loading = true;
			await this.getBundles(this.siteId);
			await this.getNonSiteBundles(this.siteId);
			this.loading = false;
		}
	}

	async unsubscribeSiteFromBundle(bundleId: number) {
		let success = true;
		this.loading = true;
		try {
			await this.bundlesService.deleteSiteBundle(this.siteId, bundleId);
		}
		catch (ex: any) {
			success = false;
			console.log(ex);
			this.toastService.showError('Site could not be unsubscribed from bundle');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.loading = true;
			await this.getBundles(this.siteId);
			await this.getNonSiteBundles(this.siteId);
			this.loading = false;
		}
	}

	async deleteBundle(bundle: BundleDisplay) {
		let success = true;
		this.loading = true;
		try {
			await this.bundlesService.deleteBundle(bundle.bundleId);
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Bundle could not be deleted');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			await this.getBundles(this.siteId);
			this.deselectBundle();
		}
	}
}
