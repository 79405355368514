import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SalesApiService } from 'services/sales-api.service';
import { CmsContent, OrderAttribution } from '@taradel/web-api-client';
import { ToastService } from 'services/toast.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { Subscription } from 'rxjs';
import { OrderDashboardComponent } from '@taradel/white-label-common';
import { saveAs } from 'file-saver';
import { ContentService } from 'services/content.service';
import { SafeValue } from '@angular/platform-browser';

@Component({
	selector: 'app-order-attribution-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
	@ViewChild(OrderDashboardComponent) orderDashboard!: OrderDashboardComponent;

	loading: boolean = true;
	exportingPdf: boolean = false;
	orderAttribution?: OrderAttribution;

	orderLoaded$ = new Subscription();
	content?: Map<string, SafeValue>;

	constructor(
		private salesApiService: SalesApiService,
		private contentService: ContentService,
		public orderLayoutService: OrderlayoutService,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		this.orderLoaded$ = this.orderLayoutService.selectedOrderLoaded.subscribe(async response => {
			this.loading = true;
			if (!!response) {
				try {
					await this.initializeComponent();
				}
				catch (error) {
					console.log(error);
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.orderLoaded$.unsubscribe();
	}

	async initializeComponent() {
		this.loading = true;
		try {
			if (!!this.orderLayoutService.selectedOrder) {
				this.orderAttribution = await this.salesApiService.getOrderAttribution(this.orderLayoutService.orderId);
			}
			this.content = await this.contentService.getSectionContent(this.orderLayoutService.selectedOrder?.affiliateId!, 'en', 'Account.Dashboard.');
		}
		 catch (error) {
			this.toastService.showError('There was a problem loading the component', 'Load Error');
			console.log(error);
		}
		 finally {
			this.loading = false;
		}
	}

	async exportPdf(): Promise<void> {
		this.exportingPdf = true;

		const reportData = this.orderDashboard.exportReportArea();
		if (!reportData || reportData === '') {
			this.exportingPdf = false;
		 	alert('We do not have the report data');
		 	return;
		}

		const fileResult = await this.salesApiService.exportAttributionPdf(this.orderLayoutService.orderId, reportData);
		if (fileResult && fileResult.data) {
			saveAs(fileResult.data, fileResult.fileName);
		}

		this.exportingPdf = false;
	}
}
