<app-customer-header [customerId]="customerId" *ngIf="customerId > 0"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-md-7">
			<div class="card p-3 mb-3">
				<form [formGroup]="addressForm">
					<div class="card-body">
						<h5 class="card-title">
							Add New Address
						</h5>
						<div class="mb-3">
							<div class="row">
								<div class="col">
									<div class="form-floating">
										<input appAutocompleteOff type="search" [options]="options" ngx-google-places-autocomplete
											#placesRef="ngx-places" id="googlePlacesSearch"
											(onAddressChange)="handleAddressChange($event)"
											formControlName="googleAddress"
											class="form-control border-primary bg-light"
											placeholder="Search for an address" />
										<label><i class="text-blue fab fa-google"></i>&nbsp;&nbsp;Search for an address</label>
									</div>
								</div>
							</div>
						</div>

						<div class="mb-3">
							<div class="form-floating">
								<input type="text" formControlName="company" class="form-control"
									[ngClass]="{ 'is-invalid': submitted && controls.company.errors }" placeholder="Company Name">
								<label for="company">Company Name</label>
							</div>
							<div *ngIf="submitted && controls.company.errors" class="invalid-feedback">
								<div *ngIf="controls.company.errors.required">Company Name is required</div>
								<div *ngIf="controls.company.errors.maxlength">Company Name must be less than 100 characters long</div>
							</div>
						</div>

						<div class="mb-3">
							<div class="row">
								<div class="col">
									<div class="form-floating">
										<input type="text" formControlName="firstName" class="form-control"
											[ngClass]="{ 'is-invalid': submitted && controls.firstName.errors }" placeholder="First Name">
										<label for="firstName">First Name</label>
									</div>
									<div *ngIf="submitted && controls.firstName.errors" class="invalid-feedback">
										<div *ngIf="controls.firstName.errors.required">First Name is required</div>
									</div>
								</div>
								<div class="col">
									<div class="form-floating">
										<input type="text" formControlName="lastName" class="form-control"
											[ngClass]="{ 'is-invalid': submitted && controls.lastName.errors }" placeholder="Last Name">
										<label for="lastName">Last Name</label>
									</div>
									<div *ngIf="submitted && controls.lastName.errors" class="invalid-feedback">
										<div *ngIf="controls.lastName.errors.required">Last Name is required</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-6">
								<div class="form-floating">
									<input type="text" formControlName="address1" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && controls.address1.errors }" placeholder="Address 1">
										<label for="address1">Address1</label>
								</div>
								<div *ngIf="submitted && controls.address1.errors" class="invalid-feedback">
									<div *ngIf="controls.address1.errors.required">Address1 is required</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating">
									<input type="text" formControlName="address2" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && controls.address2.errors }" placeholder="Address 2">
										<label for="address2">Address 2</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-3">
								<div class="form-floating">
									<input type="text" formControlName="city" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && controls.city.errors }" placeholder="City">
										<label for="city">City</label>
								</div>
								<div *ngIf="submitted && controls.city.errors" class="invalid-feedback">
									<div *ngIf="controls.city.errors.required">City is required</div>
								</div>
							</div>
							<div class="col-md-3">
								<app-state-list [state]="state" [stateClass]="submitted && controls.state.errors ? 'form-select is-invalid' : 'form-select'" [isUS]="countryCode === 'US'" (selected)="stateSelected($event)">
								</app-state-list>
							</div>
							<div class="col-md-3">
								<div *ngIf="countryCode === 'US'; else postalCodeInput" class="form-floating">
									<input type="text" formControlName="zipCode" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && controls.zipCode.errors }" placeholder="Zip Code">
										<label for="zipCode">Zip Code</label>
								</div>
								<ng-template #postalCodeInput>
									<div class="form-floating">
										<input type="text" formControlName="zipCode" class="form-control"
											[ngClass]="{ 'is-invalid': submitted && controls.zipCode.errors }" placeholder="Postal Code">
											<label for="zipCode">Postal Code</label>
									</div>
								</ng-template>
								<div *ngIf="submitted && controls.zipCode.errors" class="invalid-feedback">
									<div *ngIf="controls.zipCode.errors.required">
										<span *ngIf="countryCode === 'US'">Zip</span>
										<span *ngIf="countryCode === 'Canada'">Postal</span>
										Code is required</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-floating">
									<input type="text" formControlName="country" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && controls.country.errors }" placeholder="Country Code" maxlength="2">
										<label for="country">Country Code</label>
								</div>
								<div *ngIf="submitted && controls.country.errors" class="invalid-feedback">
									<div *ngIf="controls.country.errors.required">Country is required</div>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-6">
								<div>
									<app-phone-input
										formControlName="phoneNumber"
										id="phone"
										label="Phone Number"
										[ngClass]="{ 'is-invalid': submitted && controls.phoneNumber.errors }"
										[showErrors]="false"
										placeholder="Phone Number">
									</app-phone-input>
								</div>
								<div *ngIf="submitted && controls.phoneNumber.errors" class="invalid-feedback">
									<div *ngIf="controls.phoneNumber.errors.size">Enter valid phone number</div>
									<div *ngIf="controls.phoneNumber.errors.required">Phone Number is required</div>
								</div>
							</div>
							<div class="col-md-6">
								<div>
									<app-email-input formControlName="emailAddress"
										[ngClass]="{ 'is-invalid': submitted && controls.emailAddress.errors }"
										[showErrors]="true"
										label="Email Address"
										placeholder="Email Address"></app-email-input>
								</div>
							</div>
						</div>
					</div>

					<div class="card-body">
						<button class="btn btn-primary" (click)="saveAddress()">
							Create Address
						</button>
					</div>
				</form>

			</div>
		</div>
	</div>
</app-spinner-with-overlay>
