import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/services/authentication.service';
import { ThemeSettingsService } from 'services/theme-settings.service';
import * as Oidc from 'oidc-client';
import * as md5 from 'crypto-js/md5';
import { environment } from 'environment';
import { ToastService } from 'services/toast.service';
import { OrderCreated, OrderHubService } from 'hubs/order-hub.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	currUserProfile!: Oidc.Profile | undefined;
	environmentName: string;
	environmentClass: string;
	isCanada = environment.instance === 'Canada';

	constructor(
		private auth: AuthenticationService,
		private themeSettings: ThemeSettingsService,
		private toast: ToastService,
		private orderHub: OrderHubService) {
			switch (environment.kind) {
				case 'Local':
					this.environmentName = 'Local';
					this.environmentClass = 'environment-local';
					break;
				case 'Development':
					this.environmentName = 'Dev';
					this.environmentClass = 'environment-development';
					break;
				case 'Feature':
					this.environmentName = 'Feature';
					this.environmentClass = 'environment-feature';
					break;
				case 'Production':
					this.environmentName = 'Prod';
					this.environmentClass = 'environment-production';
					break;
			}

			this.orderHub.orderCreated.subscribe(order => this.onOrderCreated(order));
		}

	async ngOnInit() {
		await this.auth.clean();
		const user = await this.auth.getUser();
		this.currUserProfile = user?.profile;
	}

	getGravatarLink() {
		if (this.currUserProfile?.name !== undefined) {
			const hash = md5(this.currUserProfile.name.trim().toLowerCase());
			return `https://www.gravatar.com/avatar/${hash}`;
		}

		return '';
	}

	toggleAppSidebarMobile() {
		this.themeSettings.appSidebarMobileToggledEvent.emit(true);
	}

	async logout() {
		await this.auth.logout();
	}

	onOrderCreated(order: OrderCreated) {
		this.toast.showCustom('orderCreatedToast', 'success', order);
	}
}
