<app-customer-header [customerId]="customerId"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<p class="text-end">
			<button class="btn btn-success btn-rounded px-4 mx-2 rounded-pill" [disabled]="this.selectedDistributions.length < 2 || authService.organizationId !== 1"
				(click)="openModal(deleteDistributionsModal)">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Delete Distributions
			</button>
			<button	class="btn btn-success btn-rounded px-4 mx-2 rounded-pill" [disabled]="!selectionsAreValid()"
				(click)="openModal(combineModal)">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Combine Maps
			</button>
			<button	class="btn btn-success btn-rounded px-4 mx-2 rounded-pill" [disabled]="!listSelectionsAreValid()"
				(click)="openCombineListsModal(combineListsModal)">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Combine Lists
			</button>
			<a [routerLink]="this.countryCode === 'Canada' ? ['/customers', customerId, 'snapmap'] : ['/customers', customerId, 'map']"
				class="btn btn-success btn-rounded px-4 mx-2 rounded-pill">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create Map
			</a>
			<a [routerLink]="['/customers', customerId, 'personalized-mail']" class="btn btn-success btn-rounded px-4 mx-2 rounded-pill">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
				Create Customer List
			</a>
			<a [routerLink]="['/customers', customerId, 'personalized-mail', 'upload']" class="btn btn-success btn-rounded px-4 mx-2 rounded-pill">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
				Upload Customer List
			</a>
			<a [routerLink]="['/customers', customerId, 'business']" class="btn btn-success btn-rounded px-4 mx-2 rounded-pill">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
				Create Business List
			</a>
		</p>
		<div class="row d-flex justify-content-between p-2">
			<div class="col-3">
				<div class="row">
					<div class="col-10 p-0 form-floating">
						<input type="text" #name id="distributionName" name="distributionName"
						class="form-control"(keyup)="nameSearch.next(name.value)">
						<label for="distributionName">Search Distribution Name</label>
					</div>
					<div class="col-2 mt-4">
						<button *ngIf="distributionName.length > 0" class="btn p-0" (click)="clearFilter()" type="button"><span class="far fa-times-circle"></span></button>
					</div>
				</div>
			</div>
			<div class="col-2 p-0 form-floating">
				<select class="form-select w-auto" name="uSelect" [(ngModel)]="uSelectFilter" (change)="search()">
					<option value="" selected>All USelects</option>
					<option *ngFor="let x of mapType | keyvalue" value="{{x.key}}">
						{{x.value}}
					</option>
				</select>
				<label for="pageSize">Choose USelect Type</label>
			</div>
			<div class="col-7"></div>
		</div>
		<div class="d-flex justify-content-between p-2">
			<div class="form-check">
				<input class="form-check-input" type="checkbox" id="includeDeletedCheckbox" [(ngModel)]="includeDeleted" (change)="includeDeletedChecked()">
				<label class="form-check-label" for="includeDeletedCheckbox">
					Include Deleted
				</label>
			</div>
		</div>
		<div class="d-flex justify-content-between p-2" *ngIf="total > 0">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="pageSize"
				[rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>
		<div class="table-responsive" *ngIf="total > 0">
			<table class="table table-hover">
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th>Name</th>
						<th>Distribution Types</th>
						<th>Created Date</th>
						<th>Total Deliveries</th>
						<th>Delivery Method</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let distWithOrders of distributionsWithRelatedOrders" [ngClass]="{'table-danger':distWithOrders.deleted}">
						<td>
							<input #selectBox type="checkbox" [checked]="isSelected(distWithOrders)"
								(change)="selectDistribution(distWithOrders, selectBox.checked)"
								[name]="'select' + distWithOrders.distributionId"
								[id]="'select' + distWithOrders.distributionId">
						</td>
						<td>
							<div>{{ distWithOrders.name }}</div>
							<div *ngIf="distWithOrders.orderIds && distWithOrders.orderIds.length > 0">
                                <ul class="list-inline">
                                    <b>Used in order(s): </b>
                                    <li *ngFor="let orderId of distWithOrders.orderIds" class="list-inline-item mb-2"><a
                                        routerLink="/orders/{{orderId}}/summary"
                                        class="btn btn-primary btn-sm">{{orderId}}</a></li>
                                </ul>
							</div>
						</td>
						<td>
							<div *ngIf="distWithOrders.uSelect === USelectType.EDDM">
								<span>{{getEddmDeliveryType(distWithOrders)}} </span>
							</div>
							<div *ngIf="distWithOrders.uSelect === USelectType.SnapAdMail">
								<span>{{getSnapAdMailDeliveryType(distWithOrders)}} </span>
							</div>
						</td>
						<td>{{ distWithOrders.createdDate.toLocaleDateString() }}</td>
						<td>{{ distWithOrders.totalDeliveries | number }}</td>
						<td>{{ mapType.get(distWithOrders.uSelect)! }}</td>
						<td style="width: 25%">
							<ul class="list-inline">
								<li class="list-inline-item"><a
										routerLink="/customers/{{customerId}}/distributions/{{distWithOrders.distributionId}}"
										class="btn btn-primary btn-sm">Detail</a></li>
								<li class="list-inline-item"><a
									(click)="proceedToSiteSelection(distWithOrders, siteModal)"
											class="btn btn-primary btn-sm">Start Order</a></li>
								<li *ngIf="!distWithOrders.deleted" class="list-inline-item"><button
										[disabled]="authService.organizationId !== 1"
										(click)="deleteDistribution(distWithOrders.distributionId)"
										class="btn btn-primary btn-sm">Delete</button></li>
								<li *ngIf="distWithOrders.deleted" class="list-inline-item"><button
									(click)="restoreDistribution(distWithOrders.distributionId)"
									class="btn btn-primary btn-sm">Restore</button></li>
								<li class="list-inline-item" *ngIf="authService.organizationId === 1">
									<button (click)="openCustomerSearchModal(customerSearchModal, distWithOrders)" class="btn btn-primary btn-sm">
										Transfer
									</button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="d-flex justify-content-between p-2" *ngIf="total > 0">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="pageSize"
				[rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>

		<app-alert-box *ngIf="!loading && total === 0" alertMode="default">
			This customer has no saved maps or distributions.
		</app-alert-box>
	</div>
</app-spinner-with-overlay>

<ng-template #deleteDistributionsModal>
	<app-confirm-modal title="Delete confirmation" confirmText="delete" (confirmAction)="deleteDistributions()"
		(closeModal)="closeModal()">
		<p>Are you sure you want to delete these distributions:</p>
			<ul>
				<li *ngFor="let distribution of selectedDistributions">
					{{distribution.name}}
				</li>
			</ul>
	</app-confirm-modal>
</ng-template>

<ng-template #combineModal let-modal>
	<app-confirm-modal title="Combine Selected Maps" confirmText="combine" (confirmAction)="combineMaps()"
		(closeModal)="closeModal()" [disabled]="!combinedName">
		<label for="combinedNameInput" class="form-label">Combined Name</label><br>
		<input type="text" class="form-control" id="combinedNameInput" [(ngModel)]="combinedName" />
		<div class="row mt-3">
			<div class="col">
				<div class="form-check check-row">
					<input type="checkbox" class="form-check-input" [(ngModel)]="useBusiness" id="useBusiness" />
					<label for="useBusiness" class="form-label">
						Include <strong>Business</strong> addresses in this project
					</label>
				</div>
				<div *ngIf="this.countryCode !== 'Canada'" class="form-check check-row">
					<input type="checkbox" class="form-check-input" [(ngModel)]="usePOBoxes" id="usePOBoxes" />
					<label for="usePOBoxes" class="form-label">
						Include <strong>PO Boxes</strong> in this project
					</label>
				</div>
				<ng-container *ngIf="selectedDistributions[0].uSelect === USelectType.SnapAdMail">
					<div class="form-check check-row">
						<input type="checkbox" class="form-check-input" [(ngModel)]="useApartments" id="useApartments" />
						<label for="useApartments" class="form-label">
							Include <strong>Apartments</strong> in this project
						</label>
					</div>
					<div class="form-check check-row">
						<input type="checkbox" class="form-check-input" [(ngModel)]="useHouses" id="useHouses" />
						<label for="useHouses" class="form-label">
							Include <strong>Houses</strong> in this project
						</label>
					</div>
					<div class="form-check check-row">
						<input type="checkbox" class="form-check-input" [(ngModel)]="useFarms" id="useFarms" />
						<label for="useFarms" class="form-label">
							Include <strong>Farms</strong> in this project
						</label>
					</div>
				</ng-container>
			</div>
		</div>
	</app-confirm-modal>
</ng-template>

<ng-template #customerSearchModal let-modal>
	<app-confirm-modal title="Search for a customer to receive this distribution" confirmText="start transfer" (confirmAction)="transferDistribution()"
		(closeModal)="closeModal();deleteAfterTransferFalseAgain()" [disabled]="!selectedCustomer">
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r.username" [term]="t"></ngb-highlight>
		</ng-template>
		<app-spinner-with-overlay [showSpinner]="loadingCustomers">
			<div>
				<label for="customerSearch">Search Customers</label>
				<input id="customerSearch" type="text" class="form-control"
				[ngbTypeahead]="searchCustomers" (focus)="focus$.next($any($event).target.value)"
					 #instance="ngbTypeahead" [inputFormatter]="customersFormatter"  [resultTemplate]="rt"
					(selectItem)="setCustomer($event)"
				/>
				<div class="form-check mt-2">
					<input class="form-check-input" type="checkbox" id="deleteCheckbox" [(ngModel)]="deleteAfterTransfer">
					<label class="form-check-label" for="deleteCheckbox">
						Delete distribution from source account
					</label>
				  </div>
			</div>
		</app-spinner-with-overlay>
	</app-confirm-modal>
</ng-template>


<ng-template #siteModal let-modal>
	<app-confirm-modal title="Select a site to place the order from" confirmText="Proceed" cancelText="Cancel" (confirmAction)="startOrder()"
		(closeModal)="closeModal();" [disabled]="selectedSiteId === 0">
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r.siteId" [term]="t"></ngb-highlight>
		</ng-template>
		<div>
			<label for="site">Select Site</label>
			<app-sites-list [startingSiteId]="selectedSiteId" (siteSelected)="siteChanged($event.siteId)" [sites]="sites"></app-sites-list>
		</div>

	</app-confirm-modal>
</ng-template>

<ng-template #combineListsModal let-modal>
	<app-confirm-modal title="Combine Selected Lists" confirmText="combine" (confirmAction)="combineLists()"
		(closeModal)="closeModal()" [disabled]="!(combineListsQuery?.filename && combineListsQuery?.filename!.length > 0)">
		<ng-container *ngIf="combineListsQuery">
			<label for="filename" class="form-label">New List Name</label><br>
			<input type="text" class="form-control" id="filename" [(ngModel)]="combineListsQuery.filename" />
		</ng-container>
	</app-confirm-modal>
</ng-template>

