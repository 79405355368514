import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from 'environment';

import { CustomerMainComponent } from './components/customer/customer-main/customer-main.component';
import { DistributionDetailComponent } from './components/customer/distribution-detail/distribution-detail.component';

import { SiteMainComponent } from './components/site/site-main/site-main.component';
import { AllProductsComponent } from './components/products/all-products/all-products.component';
import { HomeComponent } from './components/dashboard/home/home.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { SiteSettingsComponent } from './components/site-settings/site-settings.component';
import { SiteDetailsComponent } from './components/site-details/site-details.component';
import { SiteDashboardComponent } from './components/site-dashboard/site-dashboard.component';

import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { LoginSilentComponent } from 'components/login-silent/login-silent.component';
import { RolesComponent } from './components/users/roles/roles.component';
import { CustomerDetailsComponent } from './components/customer/customer-details/customer-details.component';
import { CustomerDistributionsComponent } from './components/customer/customer-distributions/customer-distributions.component';
import { CustomerOrdersComponent } from './components/customer/orders/customer-orders.component';
import { MainComponent } from './components/layout/main.component';
import { AddressDetailsComponent } from 'components/customer/addresses/address-details/address-details.component';
import { OrderSummaryComponent } from './components/orders/order-summary/order-summary.component';
import { OrderMainComponent } from './components/orders/order-main/order-main.component';
import { OrderItemDetailsComponent } from 'components/orders/order-item-details/order-item-details.component';
import { ProductDetailsComponent } from 'components/products/product-details/product-details.component';
import { ProductOptionsComponent } from 'components/products/product-options/product-options.component';
import { ProductPricingComponent } from 'components/products/product-pricing/product-pricing.component';
import { SiteProductsComponent } from 'components/site/site-products/site-products.component';
import { WLProductDetailsComponent } from 'components/site/site-products/wl-product-details/wl-product-details.component';
import { AddWlProductComponent } from 'components/site/site-products/add-wl-product/add-wl-product.component';
import { UsersComponent } from 'components/users/users.component';
import { UserComponent } from 'components/users/user/user.component';
import { PrintersComponent } from 'components/printers/printers.component';
import { PrinterComponent } from 'components/printers/printer/printer.component';
import { PrinterZonesComponent } from 'components/printers/printer-zones/printer-zones.component';
import { CouponsComponent } from 'components/site/coupons/coupons.component';
import { CouponDetailsComponent } from 'components/site/coupons/coupon-details/coupon-details.component';
import { SitesHeaderComponent } from 'components/layout/sites-header/sites-header.component';
import { ConfigComponent } from 'components/config/all-config-counts.component';
import { ConfigForSitesComponent } from 'components/config-for-sites/config-for-sites.component';
import { LogsComponent } from 'components/logs/logs.component';
import { DashboardComponent } from 'components/orders/dashboard/dashboard.component';
import { OrderLayoutComponent } from 'components/orders/order-layout/order-layout.component';
import { OrderPaymentsComponent } from 'components/orders/order-payments/order-payments.component';
import { MapsComponent } from 'components/maps/maps.component';
import { MapPasteListComponent } from 'components/customer/map-paste-list/map-paste-list.component';
import { MapUploadListComponent } from 'components/customer/map-upload-list/map-upload-list.component';
import { SiteContentComponent } from 'components/site/site-content/site-content.component';
import { ContentKeysComponent } from 'components/site/content-keys/content-keys.component';
import { UrlsComponent } from 'components/site/urls/urls.component';
import { SiteUselectAddonsComponent } from 'components/site/site-uselect-addons/site-uselect-addons.component';
import { McnallyReportComponent } from 'components/reports/mcnally-report/mcnally-report.component';
import { SalesIndicatorsReportComponent } from 'components/reports/sales-indicators-report/sales-indicators-report.component';
import { EddmStatusReportComponent } from 'components/reports/eddm-status-report/eddm-status-report.component';
import { AddSiteUselectComponent } from 'components/site/site-uselect-addons/add-site-uselect/add-site-uselect.component';
import { SiteUselectDetailsComponent } from 'components/site/site-uselect-addons/site-uselect-details/site-uselect-details.component';
import { PersonalizedMailComponent } from 'components/personalized-mail/personalized-mail.component';
import { EquifaxB2bComponent } from 'components/equifax-b2b/equifax-b2b.component';
import { UploadYourListComponent } from 'components/upload-your-list/upload-your-list.component';
import { ContentKeyForSitesComponent } from 'components/site/content-key-for-sites/content-key-for-sites.component';
import { CreateCustomerComponent } from 'components/customer/create-customer/create-customer.component';
import { PMODComponent } from 'components/printers/pmod/pmod.component';
import { SiteUselectDesignsComponent } from 'components/site/site-uselect-designs/site-uselect-designs.component';
import { AddUselectDesignComponent } from 'components/site/site-uselect-designs/add-uselect-design/add-uselect-design.component';
import { SiteUselectDesignDetailsComponent } from 'components/site/site-uselect-designs/site-uselect-design-details/site-uselect-design-details.component';
import { UselectsComponent } from 'components/products/uselects/uselects.component';
import { UselectDetailsComponent } from 'components/products/uselects/uselect-details/uselect-details.component';
import { AddUselectComponent } from 'components/products/uselects/add-uselect/add-uselect.component';
import { SitePowerUpsComponent } from 'components/site/site-power-ups/site-power-ups.component';
import { CreateShoppingCartComponent } from 'components/customer/shopping-carts/create-shopping-cart/create-shopping-cart.component';
import { ShoppingQuotesComponent } from 'components/customer/shopping-quotes/shopping-quotes.component';
import { QuoteCheckoutComponent } from 'components/customer/shopping-quotes/quote-checkout/quote-checkout.component';
import { CustomerDesignsComponent } from 'components/customer/customer-designs/customer-designs.component';
import { CategoriesComponent } from 'components/print-and-ship/categories/categories.component';
import { CategoryProductsComponent } from 'components/print-and-ship/category-products/category-products.component';
import { AddCategoryComponent } from 'components/print-and-ship/add-category/add-category.component';
import { DesignHuddleResolver } from './resolvers/design-huddle.resolver';
import { DirectMailCustomizationComponent } from 'components/customer/shopping-carts/create-shopping-cart/direct-mail-customization/direct-mail-customization.component';
import { PrintAndShipCustomizationComponent } from 'components/customer/shopping-carts/create-shopping-cart/print-and-ship-customization/print-and-ship-customization.component';
import { ProductPowerupsComponent } from 'components/products/product-powerups/product-powerups.component';
import { OptionCategoriesComponent } from 'components/products/option-categories/option-categories.component';
import { AddOptionCategoryComponent } from 'components/products/option-categories/add-option-category/add-option-category.component';
import { OptionCategoryDetailsComponent } from 'components/products/option-categories/option-category-details/option-category-details.component';
import { CategoryOptionsComponent } from 'components/products/option-categories/category-options/category-options.component';
import { AddCategoryOptionComponent } from 'components/products/option-categories/category-options/add-category-option/add-category-option.component';
import { CategoryOptionDetailsComponent } from 'components/products/option-categories/category-options/category-option-details/category-option-details.component';
import { InductionTurnaroundComponent } from 'components/products/uselects/induction-turnaround/induction-turnaround.component';
import { DesignTurnaroundComponent } from 'components/products/uselects/design-turnaround/design-turnaround.component';
import { OrganizationsComponent } from 'components/organizations/organizations.component';
import { ShoppingCartsComponent } from 'components/customer/shopping-carts/shopping-carts.component';
import { CustomerAddressListComponent } from 'components/customer/addresses/customer-address-list/customer-address-list.component';
import { DebugUserLoginComponent } from 'components/users/debug-user-login/debug-user-login.component';
import { CreateSiteComponent } from 'components/site/create-site/create-site.component';
import { UpdatePostageComponent } from 'components/site/update-postage/update-postage.component';
import { UpdateUselectPostageComponent } from 'components/products/uselects/update-uselect-postage/update-uselect-postage.component';
import { ProductPcogsComponent } from 'components/products/product-pcogs/product-pcogs.component';
import { CaMapsComponent } from 'components/maps/ca-maps/ca-maps/ca-maps.component';
import { CaPasteRoutesComponent } from 'components/maps/ca-maps/ca-paste-routes/ca-paste-routes.component';
import { DeliveryComponent } from 'components/customer/orders/delivery/delivery.component';
import { PcogsManagementComponent } from 'components/pcogs-management/pcogs-management.component';
import { PaymentDetailsComponent } from 'components/payments/customer-payments/payment-details/payment-details.component';
import { PaymentsComponent } from 'components/payments/payments.component';
import { CreatePaymentComponent } from 'components/payments/customer-payments/create-payment/create-payment.component';
import { CustomerPaymentAttemptsComponent } from 'components/payments/customer-payment-attempts/customer-payment-attempts.component';
import { PaymentsSectionComponent } from 'components/customer/payments/payments-section/payments-section.component';
import { ChangeOrderComponent } from 'components/orders/change-order/change-order.component';
import { CreateRecurringPaymentComponent } from 'components/customer/payments/recurring-payments/create-recurring-payment/create-recurring-payment.component';
import { RefundPaymentComponent } from 'components/payments/customer-payments/refund-payment/refund-payment.component';
import { CreatePaymentProfileComponent } from 'components/customer/payments/payment-profiles/create-payment-profile/create-payment-profile.component';
import { PaymentAttemptDetailsComponent } from 'components/payments/customer-payment-attempts/payment-attempt-details/payment-attempt-details.component';
import { AttributionReportComponent } from 'components/reports/attribution-report/attribution-report.component';
import { canActivateRoute } from './route-guard/activate-route';
import { OrderBalanceReportComponent } from 'components/reports/order-balance-report/order-balance-report.component';
import { BundlesDisplayComponent } from 'components/all-bundles/bundles-display/bundles-display.component';
import { BundleDetailsComponent } from 'components/all-bundles/bundles-display/bundle-details/bundle-details.component';
import { AllBundlesComponent } from 'components/all-bundles/all-bundles.component';
import { CustomizeBundleComponent } from 'components/customer/shopping-carts/create-shopping-cart/customize-bundle/customize-bundle.component';

const routes: Routes = [
	{ path: 'login-callback', component: LoginCallbackComponent },
	{ path: 'login-silent', component: LoginSilentComponent },
	{
		path: '', component: MainComponent, children: [
			{ path: 'customers', component: CustomerMainComponent },
			{ path: 'customers/create', canActivate: [canActivateRoute], component: CreateCustomerComponent },
			{ path: 'customers/:customerId', component: CustomerDetailsComponent },
			{ path: 'customers/:customerId/distributions', component: CustomerDistributionsComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/distributions/:distributionId', component: DistributionDetailComponent },
			{ path: 'customers/:customerId/designs', component: CustomerDesignsComponent, resolve: { dh: DesignHuddleResolver } },
			{ path: 'customers/:customerId/designs/:projectId', component: CustomerDesignsComponent, resolve: { dh: DesignHuddleResolver } },
			{ path: 'customers/:customerId/addresses', component: CustomerAddressListComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/addresses/new-address', component: AddressDetailsComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/orders', component: CustomerOrdersComponent },
			{ path: 'customers/:customerId/delivery', component: DeliveryComponent },
			{ path: 'customers/:customerId/map', component: MapsComponent },
			{ path: 'customers/:customerId/map/:distributionId', component: MapsComponent },
			{ path: 'customers/:customerId/snapmap', component: CaMapsComponent },
			{ path: 'customers/:customerId/snapmap/:distributionId', component: CaMapsComponent },
			{ path: 'customers/:customerId/personalized-mail/upload', component: UploadYourListComponent },
			{ path: 'customers/:customerId/personalized-mail', component: PersonalizedMailComponent },
			{ path: 'customers/:customerId/personalized-mail/:distributionId', component: PersonalizedMailComponent },
			{ path: 'customers/:customerId/business', component: EquifaxB2bComponent },
			{ path: 'customers/:customerId/business/:distributionId', component: EquifaxB2bComponent },
			{ path: 'customers/:customerId/paste-list', component: MapPasteListComponent },
			{ path: 'customers/:customerId/snap-paste-list', component: CaPasteRoutesComponent },
			{ path: 'customers/:customerId/upload-list', component: MapUploadListComponent },
			{ path: 'logs', component: LogsComponent },
			{ path: 'customers/:customerId/payments', component: PaymentsSectionComponent, data: { key: 'tabId' } },
			{ path: 'customers/:customerId/payments/create', canActivate: [canActivateRoute], component: CreatePaymentComponent },
			{ path: 'customers/:customerId/payments/:customerPaymentId', component: PaymentDetailsComponent },
			{ path: 'customers/:customerId/payments/:customerPaymentId/refund-payment', canActivate: [canActivateRoute], component: RefundPaymentComponent },
			{ path: 'customers/:customerId/payment-profiles/create-payment-profile', canActivate: [canActivateRoute], component: CreatePaymentProfileComponent },
			{ path: 'customers/:customerId/payment-attempts', component: CustomerPaymentAttemptsComponent },
			{ path: 'customers/:customerId/payment-attempts/:customerPaymentAttemptId', component: PaymentAttemptDetailsComponent },
			{ path: 'customers/:customerId/recurring-payments/create-payment', canActivate: [canActivateRoute], component: CreateRecurringPaymentComponent },
			{ path: 'customers/:customerId/shop/carts', component: ShoppingCartsComponent },
			{ path: 'customers/:customerId/shop/quotes', component: ShoppingQuotesComponent },
			{ path: 'customers/:customerId/shop/:quoteId/checkout', component: QuoteCheckoutComponent },
			{ path: 'customers/:customerId/shop/:siteId', component: CreateShoppingCartComponent },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId/add-printandship-product', component: PrintAndShipCustomizationComponent },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId/edit-printandship-product/:cartProductId', component: PrintAndShipCustomizationComponent },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId', component: CreateShoppingCartComponent },
            { path: 'customers/:customerId/shop/:siteId/:distributionId/add-bundle/:bundleId', component: CustomizeBundleComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId/add-product', component: DirectMailCustomizationComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId/add-product/:bundleId', component: DirectMailCustomizationComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId/add-extra-copies', component: DirectMailCustomizationComponent, data: { "countryCode": environment.instance } },
			{ path: 'customers/:customerId/shop/:siteId/:distributionId/edit-product/:cartProductId', component: DirectMailCustomizationComponent, data: { "countryCode": environment.instance } },
			{ path: 'maps', component: MapsComponent },
			{ path: 'snapmap', component: CaMapsComponent },
			{ path: 'orders', component: OrderMainComponent },
			{
				path: 'orders/:orderId', component: OrderLayoutComponent, children: [
					{ path: '', redirectTo: 'summary', pathMatch: 'full' },
					{ path: 'summary', component: OrderSummaryComponent },
					{ path: 'payments', component: OrderPaymentsComponent },
					{ path: 'attribution-dashboard', component: DashboardComponent },
					{ path: 'change-order', canActivate: [canActivateRoute], component: ChangeOrderComponent },
					{ path: 'details/:orderItemId', component: OrderItemDetailsComponent, data: { "countryCode": environment.instance } }
				]
			},
			{ path: 'payments', component: PaymentsComponent },
			{ path: 'siteproducts', component: SiteProductsComponent, data: { "countryCode": environment.instance } },
			{ path: 'siteproducts/:baseProductId', component: WLProductDetailsComponent },
			{ path: 'sites/config', canActivate: [canActivateRoute], component: ConfigComponent },
			{ path: 'sites/config/:configCat/:configName', canActivate: [canActivateRoute], component: ConfigForSitesComponent },
			{ path: 'sites/content-keys', canActivate: [canActivateRoute], component: ContentKeysComponent },
			{ path: 'sites/content-keys/:contentKey', canActivate: [canActivateRoute], component: ContentKeyForSitesComponent },
			{ path: 'sites/add', canActivate: [canActivateRoute], component: CreateSiteComponent },
			{ path: 'sites', canActivate: [canActivateRoute], component: SiteMainComponent },
			{
				path: 'sites/:siteId', component: SitesHeaderComponent, children: [
					{ path: 'details', canActivate: [canActivateRoute], component: SiteDetailsComponent },
					{ path: 'urls', canActivate: [canActivateRoute], component: UrlsComponent },
					{ path: 'content', canActivate: [canActivateRoute], component: SiteContentComponent },
					{ path: 'dashboard', canActivate: [canActivateRoute], component: SiteDashboardComponent, data: { "countryCode": environment.instance } },
					{ path: 'settings', canActivate: [canActivateRoute], component: SiteSettingsComponent },
					{ path: 'products', canActivate: [canActivateRoute], component: SiteProductsComponent, data: { "countryCode": environment.instance } },
					{ path: 'products/add', canActivate: [canActivateRoute], component: AddWlProductComponent },
					{ path: 'products/:baseProductId', canActivate: [canActivateRoute], component: WLProductDetailsComponent },
					{ path: 'coupons', component: CouponsComponent },
					{ path: 'coupons/:couponId', component: CouponDetailsComponent },
					{ path: 'coupons/add', component: CouponDetailsComponent },
					{ path: 'update-postage', canActivate: [canActivateRoute], component: UpdatePostageComponent },
					{ path: 'uselect-addons', canActivate: [canActivateRoute], component: SiteUselectAddonsComponent },
					{ path: 'uselect-addons/add', canActivate: [canActivateRoute], component: AddSiteUselectComponent },
					{ path: 'uselect-addons/:uSelectId', canActivate: [canActivateRoute], component: SiteUselectDetailsComponent },
					{ path: 'uselect-designs', canActivate: [canActivateRoute], component: SiteUselectDesignsComponent },
					{ path: 'uselect-designs/add', canActivate: [canActivateRoute], component: AddUselectDesignComponent },
					{ path: 'uselect-designs/:uSelectId', canActivate: [canActivateRoute], component: SiteUselectDesignDetailsComponent },
					{ path: 'uselect-power-ups', canActivate: [canActivateRoute], component: SitePowerUpsComponent },
					{ path: 'bundles', component: AllBundlesComponent }
				]
			},

			{ path: 'reports/mcnally', canActivate: [canActivateRoute], component: McnallyReportComponent },
			{ path: 'reports/eddm-status', canActivate: [canActivateRoute], component: EddmStatusReportComponent },
			{ path: 'reports/sales-indicators', canActivate: [canActivateRoute], component: SalesIndicatorsReportComponent },
			{ path: 'reports/attribution', canActivate: [canActivateRoute], component: AttributionReportComponent },
			{ path: 'reports/order-balance', canActivate: [canActivateRoute], component: OrderBalanceReportComponent },
			{ path: 'roles', canActivate: [canActivateRoute], component: RolesComponent },
			{ path: 'printandship/categories', canActivate: [canActivateRoute], component: CategoriesComponent },
			{ path: 'printandship/categories/add', canActivate: [canActivateRoute], component: AddCategoryComponent },
			{ path: 'printandship/categories/:categoryId', canActivate: [canActivateRoute], component: CategoryProductsComponent },
			{ path: 'products', canActivate: [canActivateRoute], component: AllProductsComponent },
			{ path: 'products/add', canActivate: [canActivateRoute], component: ProductDetailsComponent },
			{ path: 'products/uselects', canActivate: [canActivateRoute], component: UselectsComponent, data: { "countryCode": environment.instance } },
			{ path: 'products/uselects/add', canActivate: [canActivateRoute], component: AddUselectComponent },
			{ path: 'products/uselects/induction-turnaround', canActivate: [canActivateRoute], component: InductionTurnaroundComponent },
			{ path: 'products/uselects/:uselectId', canActivate: [canActivateRoute], component: UselectDetailsComponent },
			{ path: 'products/uselects/:uselectId/update-postage', canActivate: [canActivateRoute], component: UpdateUselectPostageComponent },
			{ path: 'products/uselects/:uselectId/powerups', canActivate: [canActivateRoute], component: ProductPowerupsComponent },
			{ path: 'products/uselects/:uselectId/design-turnaround', canActivate: [canActivateRoute], component: DesignTurnaroundComponent },
			{ path: 'option-categories', canActivate: [canActivateRoute], component: OptionCategoriesComponent},
			{ path: 'option-categories/add', canActivate: [canActivateRoute], component: AddOptionCategoryComponent},
			{ path: 'option-categories/:categoryId', canActivate: [canActivateRoute], component: OptionCategoryDetailsComponent},
            { path: 'option-categories/:categoryId/options', canActivate: [canActivateRoute], component: CategoryOptionsComponent},
			{ path: 'option-categories/:categoryId/options/add', canActivate: [canActivateRoute], component: AddCategoryOptionComponent},
            { path: 'option-categories/:categoryId/options/:optionId', canActivate: [canActivateRoute], component: CategoryOptionDetailsComponent},
			{ path: 'products/:productId', canActivate: [canActivateRoute], component: ProductDetailsComponent },
			{ path: 'products/:productId/options', canActivate: [canActivateRoute], component: ProductOptionsComponent },
			{ path: 'products/:productId/pricing', canActivate: [canActivateRoute], component: ProductPricingComponent },
			{ path: 'products/:productId/printer-cost-of-goods', canActivate: [canActivateRoute], component: ProductPcogsComponent },
			{ path: 'templates', component: TemplatesComponent },
			{ path: 'users', canActivate: [canActivateRoute], component: UsersComponent },
			{ path: 'users/:customerId', canActivate: [canActivateRoute], component: UserComponent },
			{ path: 'printers', canActivate: [canActivateRoute], component: PrintersComponent },
			{ path: 'printers/:printerId', canActivate: [canActivateRoute], component: PrinterComponent, data: { "countryCode": environment.instance } },
			{ path: 'pmod-config', canActivate: [canActivateRoute], component: PMODComponent},
			{ path: 'printers/:printerId/printer-zones', canActivate: [canActivateRoute], component: PrinterZonesComponent },
			{ path: 'organizations', canActivate: [canActivateRoute], component: OrganizationsComponent },
			{ path: 'debug-user-login', canActivate: [canActivateRoute], component: DebugUserLoginComponent },
			{ path: 'printer-cost-of-goods', canActivate: [canActivateRoute], component: PcogsManagementComponent },
			{ path: '', component: HomeComponent },
			{ path: '**', redirectTo: '' },
		]
	}
];

@NgModule({
	imports: [CommonModule, RouterModule.forRoot(routes, {
		paramsInheritanceStrategy: 'always',
		scrollPositionRestoration: 'top'
	})],
	exports: [RouterModule],
	declarations: []
})

export class AppRoutingModule { }
