<app-product-info [productId]="productId"></app-product-info>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-12">
			<section (drop)="removeOption($event)" (dragover)="allowDrop($event)" >
				<div [class]="deleteSectionClass" class="card">
					<div class="card-header">
						<i class="fas fa-trash"></i>&nbsp;&nbsp;Delete
					</div>
					<div class="card-body delete-section"></div>
				</div>
			</section>
		</div>
	</div>
	<br/>
	<div class="row">
		<div class="col-md-3">
			<div class="panel panel-inverse">
				<div class="panel-heading">All Product Options</div>
				<ngb-accordion>
					<ngb-panel *ngFor="let productOptionCategory of allProductOptionCategories" title={{productOptionCategory.name}}>
						<ng-template ngbPanelContent>
							<div class="card new-product mt-2" draggable="true" (dragstart)="addDrag($event, productOption)"
							*ngFor="let productOption of productOptionCategory.options">
								<div class="card-body d-flex justify-content-between">
									<span class="align-self-center">{{productOption?.name}}</span>
									<button type="button" class="btn btn-small" (click)="openModal(productOption, addOptionModal)">
										<i class="fa fa-plus fa-lg me-2 ms-n2 text-success"></i>
									</button>
								</div>
							</div>
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
		<div class="col-md-3" *ngFor="let printMethod of printMethods">
			<div class="panel panel-inverse" (drop)="handleOptionDropEvent($event, printMethod.printMethodId)" (dragover)="allowDrop($event)">
				<div class="panel-heading">{{printMethod.name}}</div>
				<div class="panel-body added-products-section">
					<ul>
						<li *ngFor="let ppmo of getProductPrintMethodOptions(printMethod.printMethodId)">
							<div class="card added-product mt-2" draggable="true" (dragstart)="removeDrag($event, ppmo)">
								<div class="card-body">
									{{getOptionName(ppmo)}}
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>


<ng-template #addOptionModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add {{ optionToAdd?.name }} Option</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
				(click)="modal.close('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead"></p>
		Select all print methods that the {{ optionToAdd?.name }} option should be added to
		<div *ngFor="let printMethodSelection of printMethodSelectionList; let i = index" class="form-check my-3">
			<input type="checkbox" class="form-check-input" [(ngModel)]="printMethodSelection.selected" id="{{ 'printMethodSelection' + i }}">
			<label class="form-check-label" for="{{ 'printMethodSelection' + i }}">
				{{ printMethodSelection.printMethod.name }}
			</label>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-success btn-lg" [disabled]="disableButton()" (click)="handleAddingOptionToMethods()">Add</button>
	</div>
</ng-template>
