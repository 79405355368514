import * as signalR from '@microsoft/signalr';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';

export abstract class HubService {
	protected connection: signalR.HubConnection;

	constructor(hubRoute: string, authService: AuthenticationService) {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${environment.webApiUrl}/hubs/${hubRoute}`, {
				accessTokenFactory: async () => { return await authService.token(); },
				transport: signalR.HttpTransportType.WebSockets,
				skipNegotiation: true // this just skips falling back to long polling
			})
			.withAutomaticReconnect()
			.build();
	}
}
