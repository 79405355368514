import { Component } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Drop } from '@taradel/web-api-client';
import { numDaysBetween } from 'app/utils/num-days-between';
import { AuthenticationService } from 'services/authentication.service';
import { USelect } from 'services/distributions.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { ToastService } from 'services/toast.service';
import * as Currency from 'currency.js';

@Component({
	selector: 'app-digital-delivery-drops',
	templateUrl: './digital-delivery-drops.component.html',
	styleUrls: ['./digital-delivery-drops.component.scss']
})
export class DigitalDeliveryDropsComponent {

	loading: boolean = false;
	today = new Date();
	allowEarlierDate = false;
	todaysDate = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
	earliestDate = { year: 2000, month: this.today.getMonth() + 1, day: this.today.getDate() };
	disableBtn = false;

	constructor(
		public orderLayoutService: OrderlayoutService,
		public authService: AuthenticationService,
		private toastService: ToastService
	) { }

	dropToBeFixed(dropNumber: number): boolean {
		return this.orderLayoutService.dropsToFix.some(num => num === dropNumber);
	}

	setStartDate(drop: Drop, date: NgbDate): void {
		drop.date = new Date(date.year, date.month - 1, date.day);
		this.orderLayoutService.selectedOrderItemDropDateMap.set(drop.number, date);
		this.adjustEndDate(drop);
	}
	setEndDate(drop: Drop, date: NgbDate): void {
		drop.endDate = new Date(date.year, date.month - 1, date.day);
		this.updateQuantity(drop);
	}
	updateQuantity(drop: Drop) {
		const start = drop.date!;
		const end = drop.endDate!;
		if (end.getTime() - start.getTime() > 0) {
			const diffDays = Math.round(numDaysBetween(end, start)) + 1;
			this.orderLayoutService.selectedOrderItemAsCartProduct!.quantity = diffDays;
		}
		else {
			this.orderLayoutService.selectedOrderItemAsCartProduct!.quantity = 0;
		}
	}

	adjustEndDate(drop: Drop) {
		const drops = this.orderLayoutService.selectedOrderItemAsCartProduct?.drops!;
		const start = drops.orderDrops!.find(od => od.number === drop.number)?.date!;
		const endDate = new Date(start);
		if (this.orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId === USelect.targetedEmail) {
			this.orderLayoutService.selectedOrderItemEndDateMap.set(drop.number, new NgbDate(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()));
		}
		else {
			endDate.setDate(start.getDate() - 1 + this.orderLayoutService.selectedOrderItemAsCartProduct!.quantity);
			this.orderLayoutService.selectedOrderItemEndDateMap.set(drop.number, new NgbDate(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()));
		}
		drop.endDate = endDate;
	}

	async updateDropDates() {
		for (let i = 0; i < this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops!.length!; i++) {
			await this.updateDropDate(this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops![i]!);
		}
	}

	async updateDropDate(drop: Drop) {
		this.disableBtn = true;
		const missingDropDates = drop.date === undefined || isNaN(drop.date.getDate());
		if (missingDropDates) {
			this.disableBtn = false;
			this.toastService.showError('Specify drop dates for your drop(s)');
			return;
		}

		const start = drop.date!;
		const end = drop.endDate!;
		if (end.getTime() - start.getTime() < 0) {
			this.disableBtn = false;
			this.toastService.showWarning(`End Date cannot be before start date`);
			return;
		}

		if (this.orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId !== USelect.targetedEmail) {
			const budget = this.orderLayoutService.selectedOrderItemAsCartProduct?.budget;
			let newPrice = this.orderLayoutService.selectedOrderItemAsCartProduct!.quantity * (budget ?? this.orderLayoutService.selectedOrderItemAsCartProduct!.pricePerPiece);
			newPrice = Currency(newPrice).value;
			if (newPrice !== this.orderLayoutService.selectedOrderItemAsCartProduct!.price) {
				this.disableBtn = false;
				this.toastService.showWarning(`You have changed the duration of the campaign but not adjusted the budget to match the total price`);
				return;
			}
		}
		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDrops(drop.number);
			this.loading = false;
		}
		catch (error) {

		}
		finally {
			this.disableBtn = false;
			this.loading = false;
		}
	}
}
