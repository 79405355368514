<app-spinner-with-overlay [showSpinner]="showSpinner">
	<ng-container *ngIf="invoices.length === 0 && showSpinner === false">
		<app-alert-box alertMode="info">
			No customer invoices found.
		</app-alert-box>
	</ng-container>
	<ng-container *ngIf="invoices.length > 0 && showSpinner === false">
		<div class="row my-5">
			<div class="col">
				<div class="shadow rounded p-4">
					<ng-container *ngIf="invoices.length > 0">
						<div *ngFor="let invoice of invoices">
							<div class="row">
								<div class="col">
									<h3>Invoice {{invoice.invoice.invoiceSqid}} <br />
										Created: {{invoice.invoice.createdDate | date: 'M/d/yy'}}</h3>
								</div>
								<div class="col text-end text-danger">
									<h3>Amount Due: <span [ngStyle]="invoice.paidInFull ? { 'text-decoration': 'line-through' } : null">{{invoice.amountDue | currency}}</span>
										<span *ngIf="invoice.paidInFull" class="col text-end text-success">
											Paid
										</span>
										<br />
										<a class="btn btn-primary mt-2" (click)="copyToClipboard(invoiceLink + invoice.invoice.invoiceSqid)"
										data-toggle="tooltip" data-placement="top" title="Copy invoice link to clipboard">Copy Invoice Link</a>
									</h3>
								</div>
							</div>
							<div *ngFor="let order of invoice.invoice.invoiceOrders" class="mb-3">
								<div class="row">
									<div class="col my-auto">
										<h5>Order {{order.orderId}}</h5>
									</div>
									<div class="col-auto me-4 my-auto">
										<h5><strong>Amount Due: {{order.paymentAmount | currency}}</strong></h5>
									</div>

								</div>
								<ngb-accordion *ngIf="order !== null" #acc="ngbAccordion" id="{{ order.orderId }}">
									<ng-container *ngFor="let item of order.items; let i = index">
										<ngb-panel id="newPanel{{i}}">
											<ng-template ngbPanelTitle>
												<div class="row w-100">
													<div class="col my-auto">
														<h6>
															&nbsp;{{item.productName}}<span *ngIf="(item.jobname?.length ?? 0) > 0">- {{item.jobname}}</span>
														</h6>
													</div>
													<div class="col-auto me-4 my-auto" *ngIf="item.lines">
														<h6><strong>{{item.subtotal | currency}}</strong></h6>
													</div>
												</div>
											</ng-template>
											<ng-template ngbPanelContent>
												<ng-container *ngFor="let line of item.lines">
													<div class="row">
														<div class="col">
															<p>{{ line.lineType }}</p>
														</div>
														<div class="col text-center">
															<p *ngIf="line.quantity > 1">{{ line.quantity | number }} @ {{line.unitPrice | currency : 'USD' : 'symbol' : '1.2-3' }}</p>
														</div>
														<div class="col text-end">
															<p>{{ line.amount | currency }}</p>
														</div>
													</div>
												</ng-container>
											</ng-template>
										</ngb-panel>
									</ng-container>
								</ngb-accordion>
							</div>
							<hr />
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</app-spinner-with-overlay>
