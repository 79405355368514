import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl, FormGroup, FormArray } from '@angular/forms';
import { LineTypeEnum, OrderItem, OrderItemLine, OrderItemLineDisplay } from '@taradel/admin-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';
import { PurchaseordersService } from 'services/purchaseorders.service';
import { OrderService } from 'services/order.service';

@Component({
	selector: 'app-change-order-item',
	templateUrl: './change-order-item.component.html',
	styleUrls: ['./change-order-item.component.scss']
})
export class ChangeOrderItemComponent implements OnInit {
	LineTypeEnum = LineTypeEnum;
	orderItemForm: FormGroup;
	orderItemLineForm: FormGroup;
	inEditMode = false;
	hasPurchaseOrder = true;

	@Input() orderItem?: OrderItem;
	@Output() itemMarkedAsDeleted = new EventEmitter<{ orderItemId: number, deleted: boolean }>();
	@Output() addOrderItemLines = new EventEmitter<OrderItemLine[]>();
	@Output() removeOrderItemLines = new EventEmitter<OrderItemLine[]>();
	@Output() updateOrderItem = new EventEmitter<OrderItem>();
	@Output() addOrderItem = new EventEmitter<number>();

	constructor(private formBuilder: UntypedFormBuilder,
		public orderLayoutService: OrderlayoutService,
		private purchaseOrderService: PurchaseordersService,
		private orderService: OrderService) {
		this.orderItemForm = this.formBuilder.group({
			orderItem: new FormControl<OrderItem | undefined>(undefined, Validators.required),
			updatedOrderItem: new FormControl<OrderItem | undefined>(undefined),
			deleted: new FormControl<boolean>(false),
			deletedFromOrder: new FormControl<boolean>(false),
			newLineItems: formBuilder.array([])
		});

		this.orderItemLineForm = this.formBuilder.group({
			orderItemLineId: new FormControl<number | undefined>(undefined),
			orderItemId: new FormControl<number>(this.orderItem?.orderItemId ?? 0),
			quantity: new FormControl<number |undefined>(undefined),
			unitPrice: new FormControl<number | undefined>(undefined),
			amount: new FormControl<number | undefined>(undefined),
			lineType: new FormControl<LineTypeEnum | undefined>(undefined),
			createdDate: new FormControl<Date | undefined>(undefined),
			createdById: new FormControl<number | undefined>(undefined),
			description: new FormControl<string | undefined>(undefined)
		});
	}

	get f() {
		return this.orderItemForm.controls;
	}

	get fItems() {
		return (this.orderItemForm.controls['newLineItems'] as FormArray).controls;
	}

	get edit() {
		return this.orderItemLineForm.controls;
	}

	async ngOnInit(): Promise<void> {
		if (!this.orderItem) {
			return;
		}
		const purchaseOrder = await this.purchaseOrderService.getPurchaseOrderForOrderItem(this.orderItem.orderItemId);
		this.hasPurchaseOrder = !!purchaseOrder && purchaseOrder.status !== 'Cancelled';
		this.updateForm();
	}

	async updateForm() {
		this.f.orderItem.setValue(this.orderItem);
		this.f.deletedFromOrder.setValue(this.orderItem?.deleted);
		const reducedLines = await this.orderService.getOrderItemLinesDisplay(this.orderItem?.orderItemId!);
		reducedLines.forEach((line: OrderItemLineDisplay, index: number) => {
			const orderItemLine = this.formBuilder.group({
				orderItemLineId: new FormControl<number>(index + 1),
				orderItemId: new FormControl<number>(this.orderItem?.orderItemId!),
				quantity: new FormControl<number>(line.quantity),
				unitPrice: new FormControl<number>(line.unitPrice),
				amount: new FormControl<number>(line.amount),
				lineType: new FormControl<LineTypeEnum>(line.lineType),
				description: new FormControl<string | undefined>(line.description),
				deletedOrderItemLine: new FormControl<OrderItemLineDisplay | undefined>(undefined),
				newOrderItemLine: new FormControl<OrderItemLine | undefined>(undefined)
			});
			this.fItems.push(orderItemLine);
		});
	}

	markItemAsDeleted() {
		this.f.deleted.setValue(true);
		this.fItems.forEach(item => {
			this.removeChanges(item.get('orderItemLineId')?.value);
		});
		this.fItems.forEach(fItem => {
			const deletedOrderItemLine = {
				orderItemLineId: fItem.get('orderItemLineId')?.value,
				orderItemId: fItem.get('orderItemId')?.value,
				quantity: -fItem.get('quantity')?.value,
				unitPrice: fItem.get('unitPrice')?.value,
				amount: -fItem.get('amount')?.value,
				lineType: fItem.get('lineType')?.value,
				createdDate: fItem.get('createdDate')?.value,
				createdById: fItem.get('createdById')?.value,
				description: fItem.get('description')?.value
			} as OrderItemLine;
			fItem.get('deletedOrderItemLine')?.setValue(deletedOrderItemLine);
		});
		this.itemMarkedAsDeleted.emit({ orderItemId: this.orderItem?.orderItemId!, deleted: true });
	}

	removeDeletedFlag() {
		this.f.deleted.setValue(false);
		this.fItems.forEach(item => {
			this.removeChanges(item.get('orderItemLineId')?.value);
		});
		this.itemMarkedAsDeleted.emit({ orderItemId: this.orderItem?.orderItemId!, deleted: false });
	}

	initiateRefund(line: OrderItemLine, index: number) {
		let lineToEdit: any = undefined;
		const fItem = this.fItems.find(item => item.get('orderItemLineId')?.value === line.orderItemLineId);
		if (fItem?.get('newOrderItemLine')?.value) {
			lineToEdit = fItem?.get('newOrderItemLine')?.value;
		}
		if (!lineToEdit) {
			lineToEdit = line;
		}
		this.edit.orderItemLineId.setValue(index);
		this.edit.orderItemId.setValue(lineToEdit.orderItemId);
		this.edit.quantity.setValue(lineToEdit.quantity);
		this.edit.unitPrice.setValue(lineToEdit.unitPrice);
		this.edit.amount.setValue(lineToEdit.amount);
		this.edit.amount.setValidators([Validators.required, Validators.min(0), Validators.max(line.amount)]);
		this.edit.amount.updateValueAndValidity();
		this.edit.lineType.setValue(lineToEdit.lineType);
		this.edit.createdDate.setValue(lineToEdit.createdDate);
		this.edit.createdById.setValue(lineToEdit.createdById);
		this.edit.description.setValue(lineToEdit.description);
		this.inEditMode = true;
	}

	cancelRefund() {
		this.orderItemLineForm.reset();
		this.inEditMode = false;
	}

	removeChanges(orderItemLineId: number) {
		let linesToRemove: OrderItemLine[] = [];
		const fItem = this.fItems.find(item => item.get('orderItemLineId')?.value === orderItemLineId);
		if (fItem?.get('deletedOrderItemLine')?.value) {
			const deletedLine = fItem?.get('deletedOrderItemLine')?.value;
			linesToRemove.push(deletedLine);
			fItem?.get('deletedOrderItemLine')?.setValue(undefined);
		}
		if (fItem?.get('newOrderItemLine')?.value) {
			const newLine = fItem?.get('newOrderItemLine')?.value;
			linesToRemove.push(newLine);
			fItem?.get('newOrderItemLine')?.setValue(undefined);
		}
		this.removeOrderItemLines.emit(linesToRemove);
	}

	saveChanges() {
		if (!this.orderItemLineForm.valid) {
			return;
		}
		let linesToProcess: OrderItemLine[] = [];
		const fItem = this.fItems.find(item => item.get('orderItemLineId')?.value === this.edit.orderItemLineId.value);
		if (!!fItem) {
			fItem.get('deletedOrderItemLine')?.setValue(undefined);
			fItem.get('newOrderItemLine')?.setValue(undefined);
			let deletedOrderItemLine: OrderItemLine | undefined = undefined;
			let newOrderItemLine: OrderItemLine | undefined = undefined;

			deletedOrderItemLine = {
				orderItemLineId: fItem.get('orderItemLineId')?.value,
				orderItemId: fItem.get('orderItemId')?.value,
				quantity: -fItem.get('quantity')?.value,
				unitPrice: fItem.get('unitPrice')?.value,
				amount: -fItem.get('amount')?.value,
				lineType: fItem.get('lineType')?.value,
				createdDate: fItem.get('createdDate')?.value,
				createdById: fItem.get('createdById')?.value,
				description: fItem.get('description')?.value
			} as OrderItemLine;
			fItem.get('deletedOrderItemLine')?.setValue(deletedOrderItemLine);
			linesToProcess.push(deletedOrderItemLine);

			const editedAmount = fItem.get('amount')?.value - this.edit.amount.value;
			if (editedAmount < fItem.get('amount')?.value && !(editedAmount <= 0)) {
				const unitPrice = Math.round((editedAmount / this.edit.quantity.value) * 1000) / 1000;
				newOrderItemLine = {
					orderItemLineId: this.edit.orderItemLineId.value,
					orderItemId: this.edit.orderItemId.value,
					quantity: this.edit.quantity.value,
					unitPrice: unitPrice,
					amount: editedAmount,
					lineType: this.edit.lineType.value,
					createdDate: this.edit.createdDate.value,
					createdById: this.edit.createdById.value,
					description: this.edit.description.value
				} as OrderItemLine;
				fItem.get('newOrderItemLine')?.setValue(newOrderItemLine);
				linesToProcess.push(newOrderItemLine);
			}
		}
		this.emitEvents(linesToProcess);
		this.orderItemLineForm.reset();
		this.inEditMode = false;
	}

	emitEvents(linesToProcess: OrderItemLine[]) {
		this.removeOrderItemLines.emit(linesToProcess);
		this.addOrderItemLines.emit(linesToProcess);
	}

	// getters
	getProductName(baseProductId: number): string {
		return this.orderLayoutService.selectedOrderItems?.find(i => i.orderItem.productId === baseProductId)?.productName ?? '';
	}

	isUneditable(line: OrderItemLine) {
		const lineType = line.lineType;
		const amount = line.amount;
		return lineType === LineTypeEnum.CouponDiscount ||
			lineType === LineTypeEnum.VentureOneDiscount ||
			lineType === LineTypeEnum.SalesTax ||
			lineType === LineTypeEnum.HST ||
			lineType === LineTypeEnum.GST ||
			lineType === LineTypeEnum.PST ||
			lineType === LineTypeEnum.Postage ||
			amount < 0;
	}

	emitUpdateOrderItem() {
		this.updateOrderItem.emit(this.orderItem);
	}

	emitRestoreOrderItem() {
		this.addOrderItem.emit(this.orderItem?.orderItemId);
	}
}
