<app-customer-header [customerId]="customerId"></app-customer-header>
<div class="card border-0 p-3">
    <div class="card mb-3">
        <div class="card-body">
            <h5 class="card-title">Search</h5>
            <form (ngSubmit)="search()">
                <div class="row align-items-center">
                    <div class="col-4">
                        <app-sites-list name="primarySites" [startingSiteId]="-1" (siteSelected)="siteChanged($event.siteId)" [sites]="primarySites"></app-sites-list>
                    </div>
                    <div class="col-auto">
                        <div class="form-check">
                            <input type="checkbox" name="showExpired" class="form-check-input" [(ngModel)]="quotesSearch.showExpired" [checked]="quotesSearch.showExpired">
                            <label for="showExpired" class="form-check-label">Include Expired Quotes</label>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="form-check">
                            <input type="checkbox" name="showOrders" class="form-check-input" [(ngModel)]="quotesSearch.showOrders" [checked]="quotesSearch.showOrders">
                            <label for="showOrders" class="form-check-label">Include Quotes With Orders</label>
                        </div>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary" [disabled]="loading">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button class="btn btn-info btn-sm mx-1" type="button" title="Send Quotes Email" 
				(click)="sendQuotesEmail()">Send Quotes Email</button>
        </div>
    </div>
    <app-spinner-with-overlay [showSpinner]="loading">
        <div class="card border-0 p-3">
            <ng-container *ngIf="!quotes || quotes.length === 0">
                This customer has not yet created any quotes
            </ng-container>
            <ng-container *ngIf="!!quotes && quotes.length > 0">
                <div class="table-responsive" *ngIf="quotes.length > 0">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Site</th>
                                <th *ngIf="showOrders">Order ID</th>
                                <th>Created By</th>
                                <th>Created Date</th>
                                <th>Expiration Date</th>
                                <th>Subtotal</th>
                                <th>Sales Tax</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let quote of quotes; let i = index;">
                                <tr>
                                    <td>
                                        <input #selectBox type="checkbox" [checked]="isSelected(quote)"
                                        (change)="selectQuote(quote, selectBox.checked)"
                                        [disabled]="isDisabled(quote.siteId)"
                                        [name]="'select' + quote.quoteId"
                                        [id]="'select' + quote.quoteId">
                                    </td>
                                    <td>
                                        {{ quote.siteName }} ({{ quote.siteId }})
                                    </td>
                                    <td *ngIf="showOrders">
                                        <p *ngIf="quote.orderId! > 0">
                                            <a [routerLink]="['/orders', quote.orderId, 'summary']">{{ quote.orderId }}</a>
                                        </p>
                                    </td>
                                    <td>
                                        <p *ngIf="quote.createdBy === customerId">
                                            <a href="mailto:{{quote.createdByUsername}}">{{ quote.createdByName }}</a>
                                        </p>
                                        <p *ngIf="quote.createdBy !== customerId">
                                            Sales Rep: {{ quote.createdByName }}
                                        </p>
                                    </td>
                                    <td>
                                        {{ quote.createdDate | date:'medium' }}
                                    </td>
									<td *ngIf="!isSalesAdmin">
										{{ quote.expirationDate | date:'medium' }}
									</td>
                                    <td *ngIf="isSalesAdmin" class="input-group">
										<input type="date" class="form-control"
											(change)="expirationDateChanged(quote, $any($event).target.value)"
											[ngModel]="quote.expirationDate | date:'yyyy-MM-dd'"
											max="9999-12-31">
											<button class="btn btn-info btn-sm mx-1" type="button" title="Save"
											(click)="this.quoteToUpdate = quote; modalService.open(confirmModal);"><i class="fa fa-save mx-2"></i></button>
                                    </td>
                                    <td>
                                        {{ quote.subtotal | currency:'USD' }}
                                    </td>
                                    <td>
                                        {{ quote.salesTax | currency:'USD' }}
                                    </td>
                                    <td>
                                        {{ quote.total | currency:'USD' }}
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col">
                                                <button *ngIf="viewIndex !== i" type="button" class="btn btn-primary" (click)="viewQuote(quote, i)"
                                                data-toggle="tooltip" data-placement="top" title="Open quote">
                                                    <span class="fas fa-plus"></span>
                                                </button>
                                                <button *ngIf="viewIndex === i" type="button" class="btn btn-primary" (click)="closeQuote()"
                                                data-toggle="tooltip" data-placement="top" title="Close">
                                                    <span class="fas fa-minus"></span>
                                                </button>
                                            </div>
                                            <div class="col">
                                                <button type="button" class="btn btn-primary" (click)="copyToClipboard(quote.quoteId, i)" [disabled]="checkDate(quote)"
                                                data-toggle="tooltip" data-placement="top" title="Copy checkout link to clipboard">
                                                    <span class="fas fa-copy"></span>
                                                </button>
                                            </div>
                                            <!--
                                            <div class="col">
                                                <button type="button" class="btn btn-primary" (click)="editQuote(quote.quoteId)"
                                                data-toggle="tooltip" data-placement="top" title="Edit quote">
                                                    <span class="fas fa-edit"></span>
                                                </button>
                                            </div>
                                            -->
                                            <div class="col">
                                                <button type="button" class="btn btn-primary" (click)="checkoutQuote(quote.quoteId)" [disabled]="checkDate(quote)"
                                                data-toggle="tooltip" data-placement="top" title="Checkout quote">
                                                    <span class="fas fa-shopping-cart"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="i === viewIndex && !!quoteLineItems" class="line-items-row">
                                    <td colspan="3">
                                        <div class="card m-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-auto">
                                                        <section *ngFor="let item of quoteLineItems.categories![0].lineItems" class="row my-2">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <strong>{{ item.name }}</strong>
                                                                    <section class="ms-2">
                                                                        <ul>
                                                                            <li *ngFor="let desc of item.description">{{ desc }}</li>
                                                                        </ul>
                                                                    </section>
                                                                </div>
                                                                <div class="col-auto me-0">
                                                                    <strong>{{ item.amount | currency:'USD' }}</strong>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                    <div class="row d-flex">
                                                        <div class="col">
                                                            <strong>Subtotal:</strong>
                                                        </div>
                                                        <div class="col-auto">
                                                            <strong>{{ quoteLineItems.categories![0].subTotal | currency:'USD' }}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td colspan="3">
                                        <div class="card m-2">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <div *ngIf="!!quoteBillingAddress" class="d-flex justify-content-between">
                                                            <p>
                                                                Billing Address
                                                            </p>
                                                            <p>
                                                                {{ quoteBillingAddress.firstName }} {{ quoteBillingAddress.lastName }} <br />
                                                                {{ quoteBillingAddress.address1 }} {{ quoteBillingAddress.address2 }} <br />
                                                                {{ quoteBillingAddress.city }}, {{ quoteBillingAddress.state }} {{ quoteBillingAddress.zipCode }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <p>
                                                                Subtotal
                                                            </p>
                                                            <p>
                                                                {{ quote.subtotal | currency:'USD' }}
                                                            </p>
                                                        </div>
                                                        <div *ngIf="quote.couponDiscounts !== 0" class="d-flex justify-content-between">
                                                            <p>
                                                                Coupon <small>({{ quote.couponCode }})</small>
                                                            </p>
                                                            <p>
                                                                {{ quote.couponDiscounts | currency:'USD' }}
                                                            </p>
                                                        </div>
                                                        <div *ngIf="quote.ventureOneDiscount !== 0" class="d-flex justify-content-between">
                                                            <p>
                                                                Venture One Discount
                                                            </p>
                                                            <p>
                                                                {{ quote.ventureOneDiscount | currency:'USD' }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <p>
                                                                Tax
                                                            </p>
                                                            <p>
                                                                {{ quote.salesTax | currency:'USD' }}
                                                            </p>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <p>
                                                                Total
                                                            </p>
                                                            <p>
                                                                {{ quote.total | currency:'USD' }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
				<div class="d-flex justify-content-between p-2">
					<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
						[pageSize]="quotesSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
						(pageChange)="pageChanged()"></ngb-pagination>
					<div class="form-floating">
						<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
							(change)="pageChanged()">
							<option [ngValue]="20">20 items per page</option>
							<option [ngValue]="50">50 items per page</option>
							<option [ngValue]="100">100 items per page</option>
						</select>
						<label for="pageSize">Choose Items per Page</label>
					</div>
				</div>
            </ng-container>
        </div>
    </app-spinner-with-overlay>
</div>

<ng-template #confirmModal let-modal>
	<app-confirm-modal confirmText="update" title="Update Quote Expiration Date" (confirmAction)="updateExpirationDate()" (closeModal)="closeModal()">
			The product prices, delivery timeframe and tax calculations on this quote may be out of date.
			<br/>
			<br/>
			Do you wish to continue?
	</app-confirm-modal>
</ng-template>
